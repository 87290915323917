.slider-container {
    width: 100%;
    position: relative;
    padding-top: 20px;
    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
        z-index: 1;
        // .thumb--left {
        //     z-index: 3;
        // }
        // .thumb--right {
        //     z-index: 4;
        // }
    }
    .slider {
        position: relative;
        width: 100%;
        // .slider__track,
        // .slider__range,
        // .slider__left-value,
        // .slider__right-value {
        //     position: absolute;
        // }
        .slider__track,
        .slider__range {
            border-radius: 3px;
            height: 5px;
        }
        .slider__track {
            background-color: #000;
            width: 100%;
            // z-index: 1;
            position: relative;
        }
        .slider__range {
            background-color: #bebebe;
            // z-index: 2;
            position: absolute;
            top: 0;
        }
        .slider__left-value,
        .slider__right-value {
            color: #000000;
            font-size: 15px;
            font-weight: 600;
        }
        .slider__left-value {
            left: 6px;
        }
        .slider__right-value {
            right: -4px;
        }
    }
}

.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    &::-webkit-slider-thumb {
        background-color: #f1f5f7;
        border: 2px solid #bebebe;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
    &::-moz-range-thumb {
        background-color: #f1f5f7;
        border: 2px solid #bebebe;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
}