.banner{
  position: relative;
  .bannImg{
      position: relative;
      width: 25%;
      overflow: hidden;
      
      img{
          width: 100%;
          display: block;
          
      }
      .flippy-front, .flippy-back {
          padding: 0;
          &::after{
            content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,.4);
          }
      }
  }
  .bannSlogan{
    max-width:690px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    h2{
      font-family: 'Times New Roman';
      font-weight: bold;
      font-size: 80px;
      color: #fff;
      text-shadow: rgba(0, 0, 0, 0.4) 0 5px 8px;
      text-align: center;
      span.for{
        font-family: 'Back to Black Demo';
        font-weight: 900;
      }
      span.everyOne{
        
        display: block;
      }
    }
  }
}
.banSrc {
  position: relative;
  .form-control{
    height: 54px;
    width: 100%;
    border-radius: 30px;
    padding: 0 100px 0 30px;
    font-size: 18px;
    font-weight: 300;
  }
  .srcIcon{
    position: absolute;
    top: 0;
    right: 0;
    height: 54px;
    border-radius: 0 50px 50px 0;
    border: none;
    background: $bg-1;
    color: $bg-white;
    width: 90px;
    text-align: center;
    img{
      width: 32px;
      display: inline-block;
    }
  }
}



  
