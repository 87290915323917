@mixin input {
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}

@mixin input-4 {
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    font-family: 'Montserrat';
    font-size: 18px;
    line-height: 1.5;
    color: #fff !important;
    background-color: transparent;
    border: #fff 2px solid;
}

@mixin themeBtn {
    border-radius: 5px;
    background: $bg-1;
    color: $bg-white !important;
    line-height: 2.6;
    padding: 0 1.6rem;
    border: none;
    display: inline-block;
    border-radius: 50px;
}
@mixin shadowBox {
    background: $bg-white;
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 15px -5px;
}
@mixin timesBold {
    font-size: 35px;
    font-family: 'Times New Roman';
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 1.25rem;
}
  
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

