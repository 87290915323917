@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  color: #04041c;
  font-family: "Ofelia Std Book";
  font-weight: normal;
  font-size: 14px;
  background: #ffffff;
  line-height: 1.5;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
button {
  transition: all 300ms ease-in-out;
  outline: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.clear {
  clear: both;
}

h1 {
  clear: both;
  color: #04041c;
  font-size: 40px;
  padding: 0;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

h2 {
  color: #04041c;
  font-size: 35px;
  padding: 0 0 15px;
  position: relative;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

h3 {
  color: #04041c;
  font-size: 26px;
  padding-bottom: 15px;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

h4 {
  color: #04041c;
  font-size: 22px;
  padding-bottom: 10px;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

h5 {
  color: #04041c;
  font-size: 20px;
  padding-bottom: 10px;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

h6 {
  color: #04041c;
  font-size: 14px;
  padding-bottom: 5px;
  margin: 0;
  font-family: "Ofelia Std";
  font-weight: bold;
}

.relative {
  position: relative;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.pad_10-20 {
  padding: 10px 20px;
}

.pad-15 {
  padding: 15px;
}

.p-v-20-30 {
  padding: 20px 0 30px 0;
}

.p-t-30 {
  padding: 30px 0 0 0;
}

.p-v-75 {
  padding: 75px 0;
}

.padding_60_20 {
  padding: 0 20px 0 60px;
}

.padding_20_60 {
  padding: 0 60px 0 20px;
}

.padding_33_30 {
  padding: 0 33px 0 30px;
}

.p-v-10 {
  padding: 10px 0;
}

.pad_30 {
  padding: 30px;
}

.padding_60_30 {
  padding: 0 30px 25px 60px;
}

.padding_15_30 {
  padding: 0 30px 25px 15px;
}

.pad_0_31 {
  padding: 0 31px;
}

.pad_0_30_0 {
  padding: 0 30px 0 0;
}

.pad_15_hor {
  padding: 0 15px;
}

.pad_10_hor {
  padding: 0 10px;
}

.pad_20_hor {
  padding: 0 20px;
}

.pad_30_hor {
  padding: 0 30px;
}

.p-h-30 {
  padding: 0 30px;
}

.pad_30_ver {
  padding: 30px 0;
}

.pad-v20 {
  padding: 20px 0 !important;
}

.pad-v15 {
  padding: 15px 0;
}

.p-v-30 {
  padding: 30px 0;
}

.p-v-40 {
  padding: 40px 0;
}

.p-v-60 {
  padding: 60px 0;
}

.p-v-60-30 {
  padding: 60px 0 30px;
}

.pad-20-10 {
  padding: 20px 10px;
}

.pad_0_30_0_80 {
  padding: 0 30px 80px 30px;
}

.pad_0_15_0_30 {
  padding: 0 15px 30px 15px;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-h-40 {
  padding: 0 40px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-v-40-60 {
  padding: 40px 0 60px;
}

.ph-40 {
  padding: 0 40px;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-80 {
  margin-top: 80px;
}

.p-h-15 {
  padding: 0 15px;
}

.p-h-20 {
  padding: 0 20px;
}

.m-v15-40 {
  margin: 15px 0 40px;
}

.m-v-55-40 {
  margin: 55px 0 40px;
}

.pad_25-0 {
  padding: 25px 0;
}

.pad_20-0 {
  padding: 20px 0;
}

.pad_15-0 {
  padding: 15px 0;
}

.pad_20 {
  padding: 20px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-10 {
  margin-left: 10px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-40 {
  padding-left: 40px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.dt-sc-margin55 {
  margin: 25px 0 30px;
}

.uc {
  text-transform: uppercase;
}

.bCover {
  background-size: cover !important;
}

p {
  margin: 0;
}

.noBdr {
  border: none !important;
}

.overflowHidden {
  overflow: hidden;
}

.imgContainer {
  width: 100%;
}
.imgContainer a {
  display: block;
}
.imgContainer img {
  width: 100%;
  display: block;
}

.input-1 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #eee 1px solid;
  height: 42px;
}

.input-2 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #e4e4e4 1px solid;
  height: 42px;
  border-radius: 6px;
}

.input-3 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  border-bottom: #b7b7b7 1px solid;
  height: 50px;
}
.input-3:focus {
  box-shadow: none !important;
  outline: none;
}

.input-4 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 1.5;
  color: #fff !important;
  background-color: transparent;
  border: #fff 2px solid;
  height: 54px;
}
.input-4::-webkit-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4::-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:-ms-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:focus {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none !important;
  outline: none;
}

.input-5 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #bcddbe 1px solid;
  border-radius: 6px;
  height: 54px;
  color: #333;
}
.input-5::-webkit-input-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:-moz-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5::-moz-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:-ms-input-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:focus {
  outline: none;
}

.selectOption {
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  background: rgb(255, 255, 255) url(../../public/images/select-angle2.png) center right 10px no-repeat;
  background-size: 32px;
  padding: 0 0.75rem;
  border: #eee 1px solid;
  width: 100%;
  height: 42px;
}

.selectOption-3 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-size: 10px;
  padding: 0 0.75rem;
  border: #eee 1px solid;
  width: 100%;
  border: none;
  border-bottom: #b7b7b7 1px solid;
  height: 50px;
}
.selectOption-3:focus {
  box-shadow: none !important;
}

.textArea-1 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 1.5;
  color: #fff !important;
  background-color: transparent;
  border: #fff 2px solid;
  resize: none;
  height: 136px;
}
.textArea-1::-webkit-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1::-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:-ms-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:focus {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.2);
}

.textArea-2 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  color: #333;
  border: #bcddbe 1px solid;
  border-radius: 6px;
  resize: none;
  height: 136px;
}
.textArea-2::-webkit-input-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2::-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:-ms-input-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:focus {
  outline: none;
}

.form-group {
  margin-bottom: 0.5rem;
}

header {
  width: 100%;
  z-index: 3;
  transition: all 0.4s ease-in-out;
  background: #fff;
  border-bottom: #ddd 1px solid;
  top: -200px;
}
header .topHeader {
  padding: 0.8rem 0;
}
header .topHeader .logo {
  width: 180px;
}
header .topHeader .logo a {
  display: block;
}
header .topHeader .logo a img {
  display: block;
  width: 100%;
}
header .btmHeader {
  background: #000;
}
header .btmHeader ul li a {
  color: #fff;
  font-size: 14px;
  line-height: 52px;
  font-family: "Ofelia Std";
  font-weight: 500;
}
header.sticky .topHeader {
  padding: 0.3rem 0;
}
header.sticky .btmHeader ul li a {
  line-height: 46px;
}

.topLinks {
  padding: 0 1.2rem;
}
.topLinks ul li {
  position: relative;
}
.topLinks ul li a {
  color: #000404;
  font-size: 13px;
  display: block;
  line-height: 44px;
  padding: 0 10px;
  position: relative;
}
.topLinks ul li a:after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 0;
  background: #000;
  transform: scale(0, 1);
  transform-origin: right;
  transition: transform 0.4s ease;
  height: 2px;
  width: calc(100% - 20px);
}
.topLinks ul li a.active:after, .topLinks ul li a:hover:after {
  transform: scale(1, 1);
  transform-origin: left;
}
.topLinks ul li ul {
  position: absolute;
  top: calc(100% + 20px);
  left: -15px;
  background: #fff;
  z-index: 1;
  width: 200px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0 6px 10px;
  border-radius: 0 0 10px 10px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.4s;
  z-index: 2;
}
.topLinks ul li:hover ul {
  top: 100%;
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mainHeader {
  position: absolute;
  top: 0;
  left: 0;
}

.innerHeader {
  position: static;
  top: -100px;
}

header.sticky,
.innerHeader.sticky {
  top: 0;
  position: fixed;
  z-index: 9;
  background: #fff;
}

.signUpLang ul li {
  position: relative;
}
.signUpLang ul li:not(:last-child) {
  margin-right: 0.7rem;
}
.signUpLang ul li .hSrcBtn {
  font-size: 24px;
  color: #333;
  cursor: pointer;
}
.signUpLang ul li a {
  font-size: 14px;
  font-weight: 600;
  display: block;
  border-radius: 30px;
}
.signUpLang ul li a.logInBtn {
  background: #000;
  border: none;
  color: #fff;
  line-height: 34px;
  padding: 0 15px;
}
.signUpLang ul li a.logInBtn .svg-inline--fa {
  margin-right: 5px;
  font-size: 14px;
}
.signUpLang ul li a.logInBtn:hover {
  background: #04041c;
}
.signUpLang ul li a.signUpBtn {
  background: transparent;
  color: #333;
  border: #000 1px solid;
  line-height: 34px;
  padding: 0 15px;
}
.signUpLang ul li a.signUpBtn .svg-inline--fa {
  margin-right: 5px;
  font-size: 14px;
}
.signUpLang ul li a.signUpBtn:hover {
  background: #000;
  color: #fff;
}
.signUpLang ul li.cartIcon {
  position: relative;
}
.signUpLang ul li.cartIcon a span {
  position: absolute;
  background: #000;
  color: #fff;
  font-size: 10px;
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  left: 0;
  bottom: -3px;
}
.signUpLang ul li:hover .accoutDd {
  opacity: 1;
  visibility: visible;
}
.signUpLang .accoutDd {
  transition: all ease-in-out 0.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  background: #fff;
  right: 0;
  z-index: 2;
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.7) 0 8px 10px -5px;
}
.signUpLang .accoutDd .adtLeft {
  flex: 0 0 60px;
  max-width: 60px;
}
.signUpLang .accoutDd .adtLeft span {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #333;
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
}
.signUpLang .accoutDd .adtRight {
  flex: 1 0 0%;
  padding-left: 10px;
}
.signUpLang .accoutDd .adtRight h4 {
  font-size: 17px;
  padding-bottom: 5px;
}
.signUpLang .accoutDd .adtRight h6 {
  font-weight: normal;
  font-size: 11px;
  color: #808080;
  padding: 0;
}
.signUpLang .accoutDd .accoutDdList {
  padding: 10px 0;
  border-top: 1px solid #e3e3e3;
}
.signUpLang .accoutDd .accoutDdList ul li {
  padding: 0;
  margin: 0 0 3px;
}
.signUpLang .accoutDd .accoutDdList ul li a {
  color: #555;
  line-height: 2.2;
  padding: 0 15px;
  font-weight: normal;
  font-size: 13px;
}
.signUpLang .accoutDd .accoutDdTop {
  margin-bottom: 10px;
  padding: 10px;
}

.humbergar {
  position: fixed;
  z-index: 5;
  top: 17px;
  right: 20px;
}
.humbergar span {
  width: 30px;
  height: 2px;
  background: #cbc394;
  display: block;
  margin: 5px 0;
  transition: all ease-in-out 0.3s;
}
.humbergar span:last-child {
  width: 20px;
  margin: 0 auto;
  transition: all ease-in-out 0.3s;
}
.humbergar span:last-child {
  width: 20px;
  margin: 0 auto;
  transition: all ease-in-out 0.3s;
}

.midHeader .topLinks {
  margin-bottom: 0.5rem;
  padding: 0;
}
.midHeader .topLinks ul {
  padding-bottom: 0 !important;
}
.midHeader .topLinks ul li a {
  text-transform: capitalize;
  padding: 0;
}

footer .footerTop {
  padding: 3rem 0;
  background: #f0f0f0;
}
footer .footerBox img {
  max-width: 100%;
  display: block;
}
footer .footerBox ul li:not(:last-child) {
  margin-right: 1rem;
}
footer .footerBox ul li.faIcon a {
  display: block;
  color: #000;
  font-size: 40px;
}
footer .fBox ul li {
  font-family: "Ofelia Std Book";
}
footer .fBox ul li:not(:last-child) {
  margin-bottom: 8px;
}
footer .fBox ul li a {
  color: #040200;
  display: block;
}
footer .fBox .fBoxInner:not(:last-child) {
  margin-bottom: 20px;
}
footer .footerBtm {
  background: #000;
  color: #fff;
  padding: 0.8rem 0;
  font-family: "Ofelia Std Book";
  font-size: 12px;
}

.banner {
  position: relative;
}
.banner .bannImg {
  position: relative;
  width: 25%;
  overflow: hidden;
}
.banner .bannImg img {
  width: 100%;
  display: block;
}
.banner .bannImg .flippy-front, .banner .bannImg .flippy-back {
  padding: 0;
}
.banner .bannImg .flippy-front::after, .banner .bannImg .flippy-back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.banner .bannSlogan {
  max-width: 690px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner .bannSlogan h2 {
  font-family: "Times New Roman";
  font-weight: bold;
  font-size: 80px;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.4) 0 5px 8px;
  text-align: center;
}
.banner .bannSlogan h2 span.for {
  font-family: "Back to Black Demo";
  font-weight: 900;
}
.banner .bannSlogan h2 span.everyOne {
  display: block;
}

.banSrc {
  position: relative;
}
.banSrc .form-control {
  height: 54px;
  width: 100%;
  border-radius: 30px;
  padding: 0 100px 0 30px;
  font-size: 18px;
  font-weight: 300;
}
.banSrc .srcIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 54px;
  border-radius: 0 50px 50px 0;
  border: none;
  background: #000;
  color: #fff;
  width: 90px;
  text-align: center;
}
.banSrc .srcIcon img {
  width: 32px;
  display: inline-block;
}

.slider-container {
  width: 100%;
  position: relative;
  padding-top: 20px;
}
.slider-container .thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  z-index: 1;
}
.slider-container .slider {
  position: relative;
  width: 100%;
}
.slider-container .slider .slider__track,
.slider-container .slider .slider__range {
  border-radius: 3px;
  height: 5px;
}
.slider-container .slider .slider__track {
  background-color: #000;
  width: 100%;
  position: relative;
}
.slider-container .slider .slider__range {
  background-color: #bebebe;
  position: absolute;
  top: 0;
}
.slider-container .slider .slider__left-value,
.slider-container .slider .slider__right-value {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
}
.slider-container .slider .slider__left-value {
  left: 6px;
}
.slider-container .slider .slider__right-value {
  right: -4px;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: 2px solid #bebebe;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: 2px solid #bebebe;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.sectionTitle h2 {
  font-size: 40px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
}
.sectionTitle a.viewAll {
  color: #000000;
  border-bottom: #000 1px solid;
}

.topRatedMusic {
  padding: 1.5rem 0;
}
.topRatedMusic [class*=col-] {
  margin-top: calc(var(--bs-gutter-x) * 0.5);
  margin-bottom: calc(var(--bs-gutter-x) * 0.5);
}
.topRatedMusic .boxImg {
  height: 368px;
  border-radius: 10px;
  width: 100%;
  transition: all ease-in-out 0.4s;
  background-size: cover !important;
  overflow: hidden;
  display: block;
}
.topRatedMusic .boxImg span {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  transition: all ease-in-out 0.4s;
}
.topRatedMusic .boxImg:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.69);
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 0.4s ease;
  height: 100%;
  width: 100%;
}
.topRatedMusic .boxImg:hover:after {
  transform: scale(1, 1);
  transform-origin: bottom;
}
.topRatedMusic .boxImg:hover span {
  bottom: 50%;
  transform: translateY(50%);
}

.coursesBlock {
  padding: 2rem 0;
}
.coursesBlock [class*=col-] {
  margin-top: calc(var(--bs-gutter-x) * 0.5);
  margin-bottom: calc(var(--bs-gutter-x) * 0.5);
}

.projectInner {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 15px -5px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}
.projectInner.padBtm {
  padding-bottom: 20px;
}
.projectInner .projectInnerTop a {
  display: block;
}
.projectInner .projectInnerTop a img {
  display: block;
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.projectInner .projectInnerTop .bestSeller {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 11px;
  background: #08c1b8;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.projectInner .projectInnerTop .discountPercent {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 11px;
  background: #08c1b8;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.projectInner .projectInnerMid {
  padding: 0.6rem 0.9rem;
}
.projectInner .projectInnerMid h4 {
  font-size: 14px;
}
.projectInner .projectInnerMid h4 a {
  color: #04041c;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.projectInner .projectInnerMid p {
  color: #04041c;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Ofelia Std Book";
}
.projectInner .projectInnerMid ul.catExp {
  margin-bottom: 15px;
}
.projectInner .projectInnerMid ul.catExp li {
  color: #04041c;
  font-size: 14px;
  font-family: "Ofelia Std Book";
}
.projectInner .projectInnerMid ul.catExp li:not(:last-child) {
  margin-bottom: 5px;
}
.projectInner .projectInnerMid .weekHour {
  margin-top: 1rem;
}
.projectInner .projectInnerMid .weekHour ul li {
  font-size: 12px;
  color: #04041c;
  font-family: "Ofelia Std Book";
}
.projectInner .projectInnerMid .weekHour ul li:not(:last-child) {
  margin-right: 1.25rem;
}
.projectInner .projectInnerMid .weekHour ul li .svg-inline--fa {
  color: #000;
  margin-right: 5px;
}
.projectInner .price-sec {
  padding: 0 0.9rem 1rem;
}
.projectInner .price-sec .price-sec-text {
  font-size: 20px;
}

.redHeart {
  margin-left: auto;
}
.redHeart a {
  color: #fff;
  font-size: 10px;
  background: #f00;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
}

.starArea {
  font-size: 14px;
}
.starArea .rtng {
  color: #08c1b8;
}
.starArea .rvs {
  color: #6e6e6e;
}
.starArea ul {
  margin: 0 10px;
}
.starArea ul li {
  color: #ffc000;
}
.starArea ul li:not(:last-child) {
  margin-right: 1px;
}

.bestSeller span {
  background: #939598;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
  padding: 0 0.6rem;
  display: inline-block;
  line-height: 2;
}

.addBanner {
  padding: 1rem 0;
  overflow: hidden;
}
.addBanner .addImg {
  border: #c9b8a2 2px solid;
  border-radius: 50%;
  padding: 10px;
  position: relative;
  z-index: 1;
}
.addBanner .addImg:after {
  content: "";
  background: url(../../public/images/addImg-bg.png);
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: -3;
  background-size: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.5;
}
.addBanner .addImg span {
  display: block;
  position: relative;
  padding: 10px;
}
.addBanner .addImg span:after {
  content: "";
  position: absolute;
  height: calc(100% + 5px);
  width: calc(100% + 5px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: #c9b8a2 2px solid;
  border-radius: 50%;
}
.addBanner .addImg span img {
  display: block;
  width: 100%;
  border-radius: 50%;
}
.addBanner .addBannerDesc {
  position: relative;
}
.addBanner .addBannerDesc .addBannerDesc {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}
.addBanner .addBannerDesc .addBannerDesc h2 {
  font-size: 80px;
  color: #e36cf8;
  font-family: "Bebas Neue";
  font-weight: normal;
}
.addBanner .addBannerDesc .addBannerDesc h2 span {
  font-size: 33px;
  color: #fff;
  font-family: "Ofelia Std";
  display: block;
}
.addBanner .addBannerDesc .addBannerDesc p,
.addBanner .addBannerDesc .addBannerDesc a {
  color: #fff;
  font-size: 15px;
}
.addBanner .addBannerDesc .addBannerDesc a {
  margin-top: 3rem;
}
.addBanner .addBannerDesc .addBannerDesc::after {
  content: "";
  background: url(../../public/images/pattern-1.png);
  position: absolute;
  width: 83px;
  height: 85px;
  left: -100px;
  top: -30px;
}
.addBanner .addBannerDesc::after {
  content: "";
  background: url(../../public/images/pattern-1.png);
  position: absolute;
  width: 83px;
  height: 85px;
  right: 0;
  bottom: -30px;
}

.coursesBtnArea {
  padding: 5px 15px 20px;
}
.coursesBtnArea .coursesBtn {
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
  padding: 5px 20px;
  border-radius: 6px;
  font-size: 11px;
  text-transform: uppercase;
}
.coursesBtnArea .coursesBtn:hover {
  background: #cbc394;
  border-color: #cbc394;
}

.testimonials .react-parallax:after {
  content: "";
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
.testimonials .testiInner {
  padding: 4rem 0;
  max-width: 836px;
  width: 100%;
  margin: 0 auto;
}
.testimonials .testiInner .testiInnerTop {
  margin-bottom: 4rem;
}
.testimonials .testiInner .testiInnerTop .testiInnerTopInner {
  text-align: center;
}
.testimonials .testiInner .testiInnerTop .testiInnerTopInner span {
  width: 150px;
  display: block;
  margin: 0 auto 15px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: #000404 0 0 20px -5px;
}
.testimonials .testiInner .testiInnerTop .testiInnerTopInner span img {
  display: block;
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonials .testiInner .testiInnerBtm {
  position: relative;
  font-size: 15px;
  font-family: "Ofelia Std Book";
}
.testimonials .testiInner .testiInnerBtm:after, .testimonials .testiInner .testiInnerBtm:before {
  font-size: 125px;
  font-family: "Times New Roman";
  position: absolute;
  color: #020000;
  line-height: 40px;
  font-weight: bold;
}
.testimonials .testiInner .testiInnerBtm:after {
  content: "“";
  left: -100px;
  top: -8px;
}
.testimonials .testiInner .testiInnerBtm:before {
  content: "“";
  right: -100px;
  bottom: -8px;
  transform: rotate(180deg);
}
.testimonials .owl-prev,
.testimonials .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 !important;
  border-radius: 50% !important;
}
.testimonials .owl-prev span,
.testimonials .owl-next span {
  font-size: 40px;
  line-height: 27px;
  width: 40px;
  height: 40px;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
}
.testimonials .owl-prev {
  left: 0;
}
.testimonials .owl-next {
  right: 0;
}

.subscribeWrap .react-parallax {
  padding: 4.7rem 0;
}
.subscribeWrap .subscribeLeft {
  color: #fff;
}
.subscribeWrap .subscribeLeft h2 {
  color: #fff;
  font-size: 40px;
  font-family: "Times New Roman";
  text-transform: uppercase;
  font-weight: bold;
}
.subscribeWrap .subscribeLeft h5 {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 500;
}
.subscribeWrap .subscribeInner .subsSrc .form-control {
  background-color: transparent;
  border: #939598 3px solid;
  border-radius: 50px;
  padding: 0 158px 0 30px;
  height: 76px;
  color: #fff;
}
.subscribeWrap .subscribeInner .subsSrc .form-control::-webkit-input-placeholder {
  color: white;
}
.subscribeWrap .subscribeInner .subsSrc .form-control:-moz-placeholder {
  color: white;
}
.subscribeWrap .subscribeInner .subsSrc .form-control::-moz-placeholder {
  color: white;
}
.subscribeWrap .subscribeInner .subsSrc .form-control:-ms-input-placeholder {
  color: white;
}
.subscribeWrap .subscribeInner .subsSrc .subscribeBtn {
  color: #04041c;
  background: #939598;
  font-size: 18px;
  line-height: 76px;
  width: 178px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 0 50px 50px 0;
  font-weight: 700;
  font-family: "Ofelia Std";
  text-transform: uppercase;
}

.mainSrcArea {
  background: #cbcbcb;
  padding: 1.5rem 0;
  position: relative;
}
.mainSrcArea::after, .mainSrcArea:before {
  position: absolute;
  content: "";
  max-width: 848px;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover !important;
}
.mainSrcArea::after {
  background: url(../../public/images/src-left.png) no-repeat left top;
  left: 0;
}
.mainSrcArea::before {
  background: url(../../public/images/src-right.png) no-repeat right top;
  right: 0;
}
.mainSrcArea .srcInner {
  max-width: 820px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.courseMenu {
  margin-bottom: 1rem;
}
.courseMenu ul li a {
  color: #000404;
  font-size: 20px;
  display: block;
  line-height: 44px;
  padding: 0 15px;
  position: relative;
}
.courseMenu ul li a:after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 0;
  background: #08c1b8;
  transform: scale(0, 1);
  transform-origin: right;
  transition: transform 0.4s ease;
  height: 2px;
  width: calc(100% - 20px);
}
.courseMenu ul li a:hover, .courseMenu ul li a.active {
  color: #000;
}
.courseMenu ul li a:hover:after, .courseMenu ul li a.active:after {
  transform: scale(1, 1);
  transform-origin: left;
}

.popHoverBox {
  position: absolute;
  min-height: 100%;
  width: calc(100% + 40px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eafcfb;
  box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 28px -5px;
  z-index: 1;
  border-radius: 12px;
  transition: all ease-in-out 0.4s;
  padding: 1.2rem;
  opacity: 0;
  visibility: hidden;
}
.popHoverBox .phTop {
  margin-bottom: 1rem;
}
.popHoverBox .phtLeft {
  flex-grow: 1;
  padding-right: 20px;
}
.popHoverBox .phtLeft h4 {
  font-size: 20px;
  padding-bottom: 0;
}
.popHoverBox .phtLeft h5 {
  font-size: 14px;
  padding-bottom: 6px;
  color: #000;
  font-family: "Ofelia Std Book";
  font-weight: normal;
}
.popHoverBox .phtLeft p {
  font-size: 10px;
  color: #04041c;
  font-family: "Ofelia Std Book";
}
.popHoverBox .phtRight {
  flex: 0 0 auto;
  max-width: 100%;
}
.popHoverBox .phtRight a .svg-inline--fa {
  color: #fb020e;
}
.popHoverBox .phtRight .phtRightInner span {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}
.popHoverBox .addCart {
  margin-top: 1rem;
}
.popHoverBox .phDescription {
  margin-bottom: 1rem;
}
.popHoverBox .phList ul li {
  padding-left: 30px;
  font-size: 12px;
  color: #696972;
  font-family: "Ofelia Std Book";
  position: relative;
  /* svg {
      position: absolute;
      left: 0;
      top: 3px;
      color: #08c1b8;
  } */
}
.popHoverBox .phList ul li:not(:last-child) {
  margin-bottom: 1.5rem;
}
.popHoverBox .phList ul li:before {
  content: "";
  background: url(../images/svg/bullet.svg) top left no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 6px;
}

.projectInner:hover .popHoverBox {
  opacity: 1;
  visibility: visible;
}

.fcbox {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 15px -5px;
  border-radius: 10px;
}
.fcbox .fcboxLeft {
  flex: 0 0 280px;
  max-width: 280px;
}
.fcbox .fcboxLeft span {
  display: block;
}
.fcbox .fcboxLeft span img {
  border-radius: 10px;
}
.fcbox .fcboxRight {
  flex: 1 0 0;
  padding: 0 1.25rem;
}

.fcboxRightInner h4 {
  font-size: 1.25rem;
}
.fcboxRightInner p {
  font-size: 0.875rem;
  font-family: "Ofelia Std Book";
  margin-bottom: 0.35rem;
}
.fcboxRightInner h5 {
  font-size: 0.875rem;
  font-weight: 500;
}
.fcboxRightInner h6 {
  font-size: 0.875rem;
  font-weight: normal;
  color: #000;
  font-family: "Ofelia Std Book";
}

.newOldRate p {
  font-size: 1.1rem;
  color: #020000;
  margin: 0;
}
.newOldRate p span {
  color: #6d6c6c;
  text-decoration: line-through;
  margin-left: 1rem;
}

.featuredCourses {
  padding: 2rem 0;
}

.popularTopicList ul li a {
  display: block;
  border: #979797 2px solid;
  padding: 0.85rem;
  background: #fff;
  border-radius: 10px;
  color: #04041c;
  text-align: center;
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Ofelia Std Book";
}
.popularTopicList ul li a:hover, .popularTopicList ul li a.active {
  background: #000;
  color: #fff;
  border-color: #000;
}

.popularTopic {
  padding: 1.5rem 0 0;
}

.allMusiccourses .fcbox .fcboxLeft {
  flex: 0 0 350px;
  max-width: 350px;
}
.allMusiccourses .fcbox .fcboxLeft .bestSeller {
  position: absolute;
  left: 10px;
  top: 10px;
}

.fcboxLeft {
  flex: 0 0 350px;
  max-width: 350px;
}
.fcboxLeft .bestSeller {
  position: absolute;
  left: 10px;
  top: 10px;
}

.shortBy .shortBySpan {
  position: absolute;
  top: 8px;
  left: 1.2rem;
  font-size: 12px;
  color: #000;
}
.shortBy .form-select {
  display: block;
  padding: 1.6rem 3rem 0.4rem 1rem;
  background-position: right 0.75rem bottom 0.7rem;
  border-color: #000;
}

.amAddBanner {
  padding: 6.625rem 0;
  background: url(../../public/images/course-bg.jpg);
  background-size: cover;
}
.amAddBanner .amAddBannerInner {
  max-width: 530px;
  padding: 0 1.25rem;
  text-align: center;
  margin-left: 10%;
}
.amAddBanner .amAddBannerInner h2 {
  font-size: 80px;
  color: #000;
  font-family: "Bebas Neue";
  font-weight: normal;
}
.amAddBanner .amAddBannerInner h2 span {
  font-size: 33px;
  color: #040000;
  font-family: "Ofelia Std";
  display: block;
}

.courseDetailBann {
  height: 300px;
}

.ddd {
  height: 500px;
}

.cdInner {
  padding: 4rem 0;
  color: #fff;
}
.cdInner h2 {
  font-family: "Bebas Neue";
  color: #fff;
  font-size: 52px;
  font-weight: normal;
}
.cdInner .starArea {
  margin: 1.5rem 0;
}
.cdInner .bestSeller {
  margin-right: 20px;
}
.cdInner .rtng,
.cdInner .rvs {
  color: #fff;
}
.cdInner .createdBy {
  font-family: "Ofelia Std Book";
  font-size: 14px;
}
.cdInner .createdBy:not(:last-child) {
  margin-bottom: 20px;
}
.cdInner .createdBy p {
  font-style: italic;
}
.cdInner .createdBy p span {
  font-style: 500;
  font-family: "Ofelia Std";
  font-style: normal;
}
.cdInner .createdBy ul li:not(:last-child) {
  margin-right: 50px;
}
.cdInner .createdBy ul li span {
  display: inline-block;
  margin-right: 10px;
}
.cdInner .createdBy ul li span img {
  filter: invert(100%);
  width: 22px;
  position: relative;
  margin-top: -4px;
  display: inline-block;
}

.courseDtlsSection {
  padding: 3rem 0;
}

.whatYouLearn {
  margin-bottom: 2.5rem;
}
.whatYouLearn h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.whatYouLearn ul li {
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%;
  padding-left: 25px;
  position: relative;
  margin: 1rem 0;
}
.whatYouLearn ul li:before {
  content: "";
  background: url(../../src/images/svg/bullet.svg) top left no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 6px;
}

.courseContent h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.courseContent h6 {
  font-size: 14px;
  font-family: "Ofelia Std Book";
  color: #696972;
  font-weight: normal;
}

.cdAccordion {
  margin: 1.5rem 0;
  font-size: 13px;
  font-family: "Ofelia Std Book";
}
.cdAccordion .accordion-button {
  background: #f6f6f6;
  padding: 0.7rem 1.25rem 0.7rem 3rem;
  color: #000404;
  font-size: 13px;
}
.cdAccordion .accordion-button span {
  margin-left: auto;
  color: #000;
  font-size: 13px;
}
.cdAccordion .accordion-button:not(.collapsed) {
  color: #363636;
  background-color: #eaf5f3;
  box-shadow: none;
}
.cdAccordion .accordion-button:after {
  position: absolute;
  left: 13px;
}
.cdAccordion .accordion-button:focus {
  box-shadow: none;
}
.cdAccordion .accordion-item {
  border: none;
}
.cdAccordion .accordion-item .accordion-item .accordion-button {
  background-color: transparent;
  padding: 1rem 1.25rem 1rem 1.25rem;
  flex: 0 0 400px;
  max-width: 400px;
}
.cdAccordion .accordion-item .accordion-item .accordion-button:not(.collapsed) {
  color: #000;
}
.cdAccordion .accordion-item .accordion-item .accordion-button:after {
  position: static;
}
.cdAccordion .accordion-item .accordion-item .accordion-body {
  max-width: 400px;
  color: #696972;
  font-family: "Ofelia Std Book";
  font-size: 12px;
}
.cdAccordion .accordion-item:not(:last-child) {
  margin-bottom: 0.8rem;
}
.cdAccordion .prvTime ul li a,
.cdAccordion .prvTime ul li button {
  color: #000;
  text-decoration: underline;
  white-space: nowrap;
}
.cdAccordion .prvTime ul li a:hover,
.cdAccordion .prvTime ul li button:hover {
  color: #cbc394;
}
.cdAccordion .prvTime ul li:not(:last-child) {
  margin-right: 20px;
}
.cdAccordion .prvTime ul li span {
  display: block;
  cursor: pointer;
  white-space: nowrap;
}
.cdAccordion .prvTime ul li span:hover {
  color: #cbc394;
}
.cdAccordion .prvTime ul li span img {
  width: 15px;
  display: inline-block;
  position: relative;
  top: -3px;
  margin-right: 5px;
}
.cdAccordion .prvTime ul li span:not(:last-child) {
  margin-bottom: 0.8rem;
}

.requirements {
  margin-bottom: 2.5rem;
}
.requirements h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.requirements p {
  padding-left: 28px;
  position: relative;
}
.requirements p:after {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: #000;
}

.description {
  margin-bottom: 2.5rem;
}
.description h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
}
.description p {
  font-size: 12px;
  color: #696972;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.description p:not(:last-child) {
  margin-bottom: 1.25rem;
}
.description p + p {
  font-size: 14px;
  font-family: "Ofelia Std Book";
  color: #000404;
}
.description .thisCourse h4 {
  font-size: 22px;
  font-family: "Times New Roman";
  font-weight: bold;
}
.description .thisCourse ol {
  margin-top: 0.8rem;
}
.description .thisCourse ol li {
  color: #000404;
  font-family: "Ofelia Std Book";
  font-size: 14px;
}
.description .thisCourse ol li:not(:last-child) {
  margin-bottom: 1rem;
}

.featrueReview {
  margin-bottom: 2.5rem;
}
.featrueReview h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
  font-size: 30px;
}

.frBox {
  padding: 1.75rem;
  background: #e6f4f3;
  border-radius: 12px;
}
.frBox .frBoxImg {
  flex: 0 0 84px;
  max-width: 84px;
}
.frBox .frBoxImg span {
  display: block;
}
.frBox .frBoxImg span img {
  width: 100%;
  display: block;
}
.frBox .frBoxDesc {
  padding-left: 1.25rem;
  flex: 0 0 calc(100% - 84px);
  max-width: calc(100% - 84px);
}

.frBoxDescTop h5 {
  font-size: 15px;
  font-family: "Ofelia Std";
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.frBoxDescTop .frBoxDescTopLeft ul li {
  color: #696972;
  font-size: 12px;
  font-family: "Ofelia Std Book";
}
.frBoxDescTop .frBoxDescTopLeft ul li:not(:last-child) {
  margin-right: 30px;
}
.frBoxDescTop .frBoxDescTopRight {
  font-size: 12px;
}
.frBoxDescTop .frBoxDescTopRight ul li {
  color: #ffc000;
}
.frBoxDescTop .frBoxDescTopRight p {
  color: #696972;
  font-size: 12px;
}

.frBoxDescBtm {
  margin-top: 0.5rem;
  font-size: 12px;
  color: #000404;
}

.boughtTogether h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
  padding-bottom: 2.5rem;
}

.comboBox {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 15px -5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 15px;
  background: #fff;
  position: relative;
  border-radius: 14px;
}
.comboBox:not(:last-child) {
  margin-bottom: 20px;
}
.comboBox:not(:last-child):after {
  content: "+";
  position: absolute;
  bottom: -23px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background: #000404;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-family: "Ofelia Std";
  font-weight: bold;
  font-size: 40px;
  border-radius: 50%;
  z-index: 1;
}
.comboBox .comboBoxImg {
  flex: 0 0 242px;
  max-width: 242px;
}
.comboBox .comboBoxImg span {
  display: block;
}
.comboBox .comboBoxImg span img {
  display: block;
  width: 100%;
  border-radius: 14px 0 0 14px;
}
.comboBox .comboBoxDesc {
  flex: 0 0 calc(100% - 242px);
  padding: 0.8rem 1.25rem;
}

.comboBoxDescLeft h5 {
  font-size: 15px;
  padding-bottom: 0.5rem;
}
.comboBoxDescLeft p {
  font-size: 13px;
  margin-bottom: 0.2rem;
}

.comboBoxDescRight li {
  font-size: 17px;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.comboBoxDescRight li.through {
  text-decoration: line-through;
  color: #aeafad;
}

.comboBoxBtm {
  margin-top: 1.6rem;
}
.comboBoxBtm .comboBoxBtmLeft {
  flex: 0 0 auto;
  max-width: 100%;
}
.comboBoxBtm .comboBoxBtmLeft p {
  font-size: 16px;
  font-family: "Ofelia Std Book";
}
.comboBoxBtm .comboBoxBtmLeft p span.currentPrice {
  font-family: "Ofelia Std";
  font-weight: 500;
}
.comboBoxBtm .comboBoxBtmLeft p span.oldPrice {
  text-decoration: line-through;
  margin-left: 10px;
  color: #696972;
}
.comboBoxBtm .comboBoxBtmRight {
  flex: 0 0 auto;
  max-width: 100%;
}
.comboBoxBtm .comboBoxBtmRight a {
  border: #bdbdbd 1px solid;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0 30px;
  font-size: 16px;
  color: #000404;
  display: block;
  line-height: 3;
}

.showMoreBtn {
  background: #cccccc;
  line-height: 36px;
  padding: 0 2rem;
  border-radius: 8px;
  border: none;
  color: #04041c;
}

.studentsBought {
  margin-bottom: 3rem;
}
.studentsBought h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.studentsBought .projectInner .projectInnerMid h4 a {
  font-size: 13px;
  font-weight: normal;
}
.studentsBought .price-sec {
  padding: 0 0.9rem 1rem 0.9rem;
}
.studentsBought .price-sec .price-sec-text {
  font-size: 18px;
}
.studentsBought .price-sec .price-sec-text span {
  font-size: 14px;
  color: #aeafad;
  text-decoration: line-through;
}
.studentsBought .totalUpdate {
  margin-top: 0.5rem;
}
.studentsBought .totalUpdate ul li {
  font-size: 12px;
  color: #696972;
}
.studentsBought .totalUpdate ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.studentsBought .totalUpdate ul li span.currentPrice {
  font-size: 14px;
  color: #04041c;
  font-family: "Ofelia Std";
  font-weight: 500;
  margin-right: 10px;
}
.studentsBought .totalUpdate ul li span.line-through {
  margin-right: 15px;
}
.studentsBought .totalUpdate ul li span.percentOff {
  font-size: 14px;
  color: #000;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.studentsBought .starArea {
  font-size: 12px;
}

.instructor {
  margin-bottom: 2.5rem;
}
.instructor h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.instructor .instructorLeft {
  flex: 0 0 194px;
  max-width: 194px;
}
.instructor .instructorLeft span {
  display: block;
}
.instructor .instructorLeft span img {
  display: block;
  width: 100%;
  border-radius: 16px;
}
.instructor .instructorRight {
  flex: 0 0 calc(100% - 194px);
  max-width: calc(100% - 194px);
  padding: 0 1.25rem;
}
.instructor .irBtm {
  margin-top: 1rem;
  font-size: 13px;
}

.studentFeedback {
  margin-bottom: 2.5rem;
}
.studentFeedback h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.studentFeedback .pbArea .pbWrap:not(:last-child) {
  margin-bottom: 1rem;
}
.studentFeedback .pbArea .pbWrap .pbWrapLeft {
  flex: 0 0 100px;
  max-width: 100px;
  font-size: 14px;
  color: #696972;
}
.studentFeedback .pbArea .pbWrap .pbWrapMid {
  flex: 1 0 0;
}
.studentFeedback .pbArea .pbWrap .pbWrapRight {
  flex: 0 0 70px;
  max-width: 70px;
  padding-left: 1.25rem;
}

.reviews h3 {
  font-size: 35px;
  font-family: "Times New Roman";
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
.reviews .reviewBox {
  border: #d5d8d8 1px solid;
  border-radius: 16px;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}
.reviews .reviewBoxIcon {
  flex: 0 0 90px;
}
.reviews .reviewBoxIcon span {
  background: #000000;
  font-size: 20px;
  text-transform: uppercase;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-family: "Ofelia Std";
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  display: block;
}
.reviews .reviewBoxDesc {
  flex-grow: 1;
}
.reviews .reviewBoxDesc span.rtng {
  font-family: "Ofelia Std";
  font-weight: bold;
  font-size: 15px;
}
.reviews .reviewBoxDesc .dateMonth {
  color: #696972;
  font-size: 12px;
}
.reviews .reviewBoxDesc .reviewBoxDescDtls {
  margin-top: 0.5rem;
  font-size: 13px;
}
.reviews .showReview {
  color: #000;
  font-size: 15px;
  font-family: "Ofelia Std";
  font-weight: bold;
  text-decoration: underline;
}

.courseRight {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.11);
  position: sticky;
  top: 140px;
}

.videoArea img {
  display: block;
  width: 100%;
  border-radius: 20px;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  font-size: 60px;
  color: #db0303;
  line-height: 1;
  background-color: transparent;
  border: none;
}

.line-through {
  text-decoration: line-through;
}

.cartBuyTop {
  padding: 1.25rem;
}
.cartBuyTop p {
  font-size: 25px;
  font-family: "Ofelia Std";
  font-weight: 500;
  margin-bottom: 1rem;
}
.cartBuyTop p span {
  font-size: 20px;
  color: #949497;
  font-family: "Ofelia Std Book";
}
.cartBuyTop ul li:not(:last-child) {
  margin-right: 1rem;
}
.cartBuyTop small {
  font-size: 12px;
  margin-top: 0.5rem;
  display: block;
}
.cartBuyTop .addCartBtn {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
  width: 100%;
  font-size: 16px;
  max-width: 264px;
  font-family: "Ofelia Std";
  font-weight: 500;
  line-height: 3;
}
.cartBuyTop .addCartBtn:hover {
  background: #3f3f3f;
}
.cartBuyTop .buyNowBtn {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
  background: #ccc;
  color: #04041c;
  font-size: 16px;
  width: 100%;
  max-width: 264px;
  font-family: "Ofelia Std";
  font-weight: 500;
  line-height: 3;
}
.cartBuyTop .buyNowBtn:hover {
  background: #3f3f3f;
  color: #fff;
}

.courseIncludes {
  padding: 1rem 2rem 1.25rem;
}
.courseIncludes h5 {
  font-size: 16px;
}
.courseIncludes ul li {
  position: relative;
  padding-left: 1.9rem;
  color: #04041c;
}
.courseIncludes ul li:not(:last-child) {
  margin-bottom: 0.8rem;
}
.courseIncludes ul li span {
  position: absolute;
  left: 0;
  top: 5px;
  width: 0.9rem;
}
.courseIncludes ul li span img {
  display: block;
  max-width: 100%;
}

.shereGift {
  padding: 1rem 1.8rem;
  border-top: #ebebeb 1px solid;
}
.shereGift ul li a {
  display: block;
  color: #3e3e3e;
  font-family: "Ofelia Std";
  font-weight: bold;
  font-size: 13px;
}
.shereGift ul li a:hover {
  color: #000;
}
.shereGift ul li a span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  position: relative;
  top: -4px;
}
.shereGift ul li a span img {
  display: inline-block;
  max-width: 100%;
}

.tranding {
  background: #f2f2f2;
  padding: 1.25rem 2rem;
}
.tranding h5 {
  font-size: 16px;
}
.tranding p {
  color: #414141;
}
.tranding .tryBusiness {
  background: #fff;
  border-radius: 30px;
  line-height: 34px;
  color: #04041c;
  font-size: 13px;
  padding: 0 2.54rem;
}
.tranding .tryBusiness:hover {
  background: #000;
  color: #fff;
}

.modal-video {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.addCartBtn {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}
.addCartBtn:hover {
  background: #04041c;
  color: #fff;
}

.addCartArea {
  padding: 1rem 1.25rem 1.25rem;
}
.addCartArea .addCartBtn {
  border-radius: 6px;
}

.studentsBought.proRtd {
  margin-top: 1.25rem;
}
.studentsBought.proRtd .sectionTitle {
  margin-bottom: 1.25rem;
}
.studentsBought.proRtd .sectionTitle h3 {
  padding-bottom: 0;
}

.rc-input-number {
  height: 36px !important;
  font-size: 14px;
  line-height: 36px !important;
}

.rc-input-number-handler {
  height: 17px !important;
}

.inputArea {
  margin-top: 2.25rem;
}
.inputArea p {
  margin-right: 1rem;
}
.inputArea ul li {
  padding-left: 1.5rem;
  position: relative;
}
.inputArea ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.inputArea ul li:before {
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 6px;
  background: url(../../src/images/svg/bullet.svg) top left no-repeat;
}

.addBuy {
  margin-top: 2.25rem;
}
.addBuy ul li:not(:last-child) {
  margin-right: 0.8rem;
}
.addBuy ul li a, .addBuy ul li .buyNowShop {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
  font-size: 18px;
  padding: 0 3.5rem;
}
.addBuy ul li a:hover, .addBuy ul li .buyNowShop:hover {
  background: #04041c;
  color: #fff;
}
.addBuy ul li a.acBtn, .addBuy ul li .buyNowShop.acBtn {
  background: #ccc;
  color: #04041c;
}
.addBuy ul li a.acBtn:hover, .addBuy ul li .buyNowShop.acBtn:hover {
  background: #000;
  color: #fff;
}

.zoomerArea {
  padding: 4rem 0 1rem;
}
.zoomerArea .producDetailRight h2 {
  font-family: "Times New Roman";
  font-weight: bold;
  font-size: 35px;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}
.zoomerArea .producDetailRight .pdrTop {
  border-bottom: #d7d7d7 1px solid;
  padding-bottom: 0.8rem;
}
.zoomerArea .producDetailRight .pdrTop ul li {
  position: relative;
}
.zoomerArea .producDetailRight .pdrTop ul li:not(:last-child) {
  margin-right: 0.8rem;
}
.zoomerArea .producDetailRight .pdrTop ul li svg {
  color: #ffc000;
}
.zoomerArea .producDetailRight .pdrTop ul li.ratingBar {
  margin-left: 0.8rem;
}
.zoomerArea .producDetailRight .pdrTop ul li.ratingBar:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 1px;
  background: #000;
  left: -12px;
  top: 6px;
}
.zoomerArea .producDetailRight .totalUpdate .prOffer {
  margin-top: 2rem;
}
.zoomerArea .producDetailRight .totalUpdate .prOffer span.currentPrice {
  font-size: 18px;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.zoomerArea .producDetailRight .totalUpdate .prOffer span.line-through {
  font-size: 17px;
  color: #949497;
}
.zoomerArea .producDetailRight .totalUpdate .prOffer span.percentOff {
  font-size: 17px;
  color: #000;
}
.zoomerArea .producDetailRight .totalUpdate .prOffer span:not(:last-child) {
  margin-right: 0.8rem;
}

.prodectListLeft {
  flex: 0 0 auto;
  width: 25%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.prodectListWrap {
  padding: 2.5rem 0;
}

.listBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 15px;
  background: #fff;
  border-radius: 14px;
}
.listBox:not(:last-child) {
  margin-bottom: 1.25rem;
}
.listBox .listBoxLeft {
  flex: 0 0 216px;
  max-width: 216px;
}
.listBox .listBoxLeft span {
  display: block;
}
.listBox .listBoxLeft span img {
  display: block;
  width: 100%;
  border-radius: 14px 0 0 14px;
}
.listBox .listBoxLeft span.bestSeller {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 11px;
  background: #08c1b8;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.listBox .listBoxRight {
  flex: 0 0 calc(100% - 216px);
  padding: 0.8rem 1.25rem;
}
.listBox .listBoxRight h5 {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
}
.listBox .listBoxRight .limitedTime {
  margin-top: 1.25rem;
}
.listBox .listBoxRight .limitedTime p {
  border: #ab0105 1px solid;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  color: #ab0105;
  font-size: 12px;
  font-family: "Ofelia Std";
  font-weight: 500;
  display: inline-block;
}
.listBox .listBoxRight .prOffer {
  margin-top: 0.5rem;
}
.listBox .listBoxRight .prOffer span:not(:last-child) {
  margin-right: 0.8rem;
}
.listBox .listBoxRight .prOffer span.currentPrice {
  font-size: 18px;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.listBox .listBoxRight .prOffer span.line-through {
  font-size: 17px;
  color: #949497;
}
.listBox .listBoxRight .prOffer span.percentOff {
  font-size: 17px;
  color: #08c1b8;
}
.listBox .listBoxRight .prOffer ul li {
  font-family: "Ofelia Std";
  font-weight: 500;
  color: #6e6e6e;
}
.listBox .listBoxRight .prOffer ul li.saveColor {
  color: #ab0105;
}
.listBox .listBoxRight .prOffer ul li:not(:last-child) {
  margin-right: 1.25rem;
}
.listBox .listBoxRight .freeDelivery {
  margin-top: 0.5rem;
  color: #04041c;
  font-family: "Ofelia Std";
  font-weight: 500;
}

.loadMoreArea {
  margin-top: 2.5rem;
  padding: 0 2rem;
  text-align: center;
}
.loadMoreArea .loadMore {
  font-size: 15px;
  color: #6e6e6e;
  background: #eaeaea;
  line-height: 56px;
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
}
.loadMoreArea .loadMore:hover {
  background: #000;
  color: #fff;
}

.prodectListLeftInner h2 {
  padding: 0;
  border-bottom: #eae2e2 1px solid;
}

.radioBox label svg {
  color: #ffc000;
  font-size: 12px;
}
.radioBox label span {
  margin-left: 40px;
  font-size: 14px;
  color: #04041c;
}

.prodectListLeftInner .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #e8f8f7;
  box-shadow: none;
}
.prodectListLeftInner .accorBox ul li:not(:last-child) {
  margin-bottom: 0.7rem;
}
.prodectListLeftInner .accorBox ul li a {
  display: block;
  color: #6d6c6c;
}
.prodectListLeftInner .accorBox ul li a:hover {
  color: #000;
}
.prodectListLeftInner .accordion-button {
  padding: 0.5rem 1.25rem;
  font-size: 15px;
}
.prodectListLeftInner .accordion-button:focus {
  box-shadow: none;
}
.prodectListLeftInner .accordion-button::after {
  width: 0.7rem;
  height: 0.7rem;
  background-size: 0.7rem;
}

.Login {
  background: url(../../public/images/subscribe-bg.jpg) center center no-repeat;
  min-height: 100vh;
  background-size: cover;
}
.Login .loginWrap {
  width: 70%;
  margin: 0 auto;
}
.Login .loginWrap .LoginInner {
  flex: 0 0 560px;
  max-width: 560px;
  background: rgba(255, 255, 255, 0.99);
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px -5px;
}
.Login .loginWrap .LoginInner .loginForm {
  width: 100%;
}
.Login .logo {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 0.8rem 0;
}
.Login .logo img {
  display: inline-block;
  max-width: 260px;
}
.Login h2 {
  text-align: center;
  padding-bottom: 20px;
}
.Login .loginFormInner {
  max-width: 460px;
  margin: 1.5rem auto 0;
  padding: 2rem;
}
.Login .loginFormInner .form-control:focus {
  box-shadow: none;
  border-color: #c6c3c3;
}
.Login .loginFormInner a {
  color: #000;
}
.Login .loginFormInner .logins.text-success ul li {
  font-size: 18px;
  font-weight: bold;
}
.Login .loginFormInner .logins.text-danger ul li {
  font-size: 18px;
  font-weight: bold;
}
.Login .form-control {
  padding: 0.375rem 1.4rem;
}
.Login .form-floating > label {
  left: 1.2rem;
}
.Login .logInBtn {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
  width: 100%;
  line-height: 3.4;
  font-family: "Ofelia Std";
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.regWrap {
  flex: 0 0 700px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.99);
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px -5px;
  margin-right: 100px;
  padding: 3rem;
  overflow-y: auto;
}
.regWrap .logo {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 0.8rem 0;
  margin-bottom: 1rem;
}
.regWrap .logo img {
  display: inline-block;
  max-width: 260px;
}
.regWrap h2 {
  text-align: center;
  padding-bottom: 10px;
}
.regWrap h6 {
  text-align: center;
  padding-bottom: 20px;
  font-size: 14px;
  font-family: "Ofelia Std Book";
  font-weight: normal;
  color: #676565;
}
.regWrap .width-auto {
  width: auto !important;
  display: inline-block !important;
  padding: 0 5.6rem;
}
.regWrap .regWrapInner {
  margin-top: 2rem;
}
.regWrap .chofeRadio {
  margin: 1rem 0;
}
.regWrap .chofeRadio ul li {
  font-size: 16px;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.regWrap .chofeRadio ul li:not(:last-child) {
  margin-right: 20px;
}
.regWrap .form-control.uploadFile {
  padding: 0.375rem 0.75rem;
}
.regWrap .form-control:focus,
.regWrap .form-select:focus {
  box-shadow: none;
  border-color: #c6c3c3;
}
.regWrap .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.2rem;
  font-size: 0.9rem;
}
.regWrap .form-floating > label {
  left: 1.2rem;
}
.regWrap .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
}
.regWrap .radioWrap ul li:not(:last-child) {
  margin-right: 20px;
}
.regWrap .radioWrap ul li.radioTitle {
  font-weight: bold;
}

.bgWgitePad {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 0 1.25rem 1.25rem 1.25rem;
}
.bgWgitePad .cartpage table {
  margin: 0 0 10px;
  width: 100%;
  transition: height 0.3s ease-in;
}

.cartMsg {
  background: #c1d7d6;
  padding: 10px 20px;
}
.cartMsg h4 {
  padding: 0;
  font-weight: normal;
}
.cartMsg a {
  background: #fff;
  display: block;
  padding: 0 20px;
  font-weight: 600;
  color: #333;
  line-height: 34px;
  font-size: 14px;
  border-radius: 30px;
}

.cartpage table {
  margin: 0 0 10px;
}

.cart-items {
  width: 100%;
  transition: height 0.3s ease-in;
}
.cart-items tr td.first {
  padding-left: 0;
  text-align: left;
}
.cart-items tr th.first {
  padding-left: 0;
  text-align: left;
}
.cart-items tr td.last {
  padding-right: 0;
  text-align: right;
}
.cart-items tr th.last {
  padding-right: 0;
  text-align: right;
}
.cart-items tr td {
  border-bottom: 1px solid #bcc0c3;
  border-radius: 0 !important;
  text-align: center;
}
.cart-items tr th {
  border-bottom: 1px solid #bcc0c3;
  border-radius: 0 !important;
  text-align: center;
}
.cart-items th {
  color: #616d77;
  text-align: right;
  padding: 10px 8px;
  font-weight: 600;
}

.cart-item td {
  padding: 10px 0;
  padding-left: 0px;
}

.cart-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
}
.cart-image img {
  width: 100%;
  display: block;
}

.cart-item-product-wrap {
  display: inline-block;
  margin-left: 30px;
  vertical-align: middle;
}

.cart-title {
  color: #141414;
}
.cart-title a {
  color: #141414;
}
.cart-title a:hover {
  color: #000;
}

.cart-vendor {
  color: #a2acb4;
  font-size: 14px;
  margin-top: 10px;
  color: #a2acb4;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.cart-variant {
  color: #a2acb4;
  font-size: 14px;
  margin-top: 10px;
  color: #a2acb4;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.cart-item-remove {
  color: #bb300e;
  font-size: 13px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.cart-item-rmv {
  display: block;
}

.controls2 {
  font-size: 26px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 28px;
  border: 2px solid #e6e8ee;
  background: #fff;
  cursor: pointer;
  outline: none;
  color: #000;
}
.controls2:hover {
  border-color: #000;
  background: #000;
  color: #fff;
}

.qtyInput2 {
  font-size: 16px;
  width: 60px;
  height: 40px;
  line-height: 24px;
  border: 2px solid #e6e8ee;
  background: transparent;
  text-align: center;
  margin: 0 3px;
  background: #ffffff;
  outline: none !important;
}

.priceControl input[type=number] {
  -moz-appearance: textfield;
}

.giftcardMsg {
  display: none;
  margin-bottom: 10px;
}

.cart-instructions {
  font-size: 16px;
  margin-top: 10px;
}
.cart-instructions textarea {
  font-size: 14px;
  resize: none;
  padding: 15px;
  width: 100%;
  border: #ddd 1px solid;
}

.cart-totals {
  font-size: 16px;
  margin-top: 10px;
}
.cart-totals .c-order-total {
  color: #141414;
  display: block;
  margin-bottom: 10px;
}
.cart-totals .c-order-total .cart-price {
  margin-left: 5px;
}
.cart-totals input {
  background: #000;
  font-weight: 700;
  color: #fff;
  -webkit-appearance: none;
  padding: 0 30px;
  font-size: 16px;
  border-radius: 5px;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 15px;
}

.meta {
  color: #a2acb4;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: initial;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.checkout {
  padding: 3rem 0;
}

.checkout .form-control {
  margin-bottom: 1.3rem;
  line-height: 2;
  border-color: #e3e3e3;
}

.checkout-discount {
  position: relative;
  max-width: 350px;
  margin-bottom: 0.5rem;
}
.checkout-discount .form-control {
  background-color: transparent !important;
  border-radius: 0.3rem;
  border: 0.1rem dashed #dad8d8;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  margin-bottom: 0;
}
.checkout-discount .form-control:focus {
  border-color: #000;
  outline: none !important;
  box-shadow: none;
}
.checkout-discount label {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  font-weight: 400;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
}
.checkout-discount label span {
  color: #000;
}

.checkout label {
  margin-bottom: 0.5rem;
}

.summary {
  border: 0.1rem dashed #d7d7d7;
  border-radius: 0.3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.summary .summary-title {
  letter-spacing: 0;
  padding: 1em 2rem;
  border-bottom: 0.1rem solid #eee;
  margin-bottom: 1.5rem;
}
.summary .tableSummaryArea {
  padding: 0 2rem;
}

.table.table-summary {
  line-height: 1.86;
  color: #666;
  border: none;
  margin-bottom: 0;
}
.table.table-summary .totalRs {
  font-weight: bold;
  font-size: 1.2rem;
  color: #04041c;
}

.table.table-summary tbody td,
.table.table-summary thead th {
  vertical-align: middle;
  border-top: none;
}

.table.table-summary tbody td {
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid #ebebeb;
}
.table.table-summary tbody td a {
  font-weight: 600;
  color: #04041c;
}

.table.table-summary thead th {
  border-bottom: none;
  padding: 0 0 0.3rem;
}

.table.table-summary > :not(:first-child) {
  border-top: none;
}

.accordionSummary .accordion-item {
  border: none;
}
.accordionSummary .accordion-body {
  padding: 1rem 2rem 1rem;
  color: #666;
  margin-bottom: 1rem;
  background: #fbfbfb;
}
.accordionSummary .accordion-header:not(:last-child) {
  margin-bottom: 1rem;
}
.accordionSummary .accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
  box-shadow: none;
}
.accordionSummary .accordion-button:not(.collapsed):after {
  background: #04041c;
}
.accordionSummary .accordion-button {
  position: relative;
  padding: 0 1.25rem 0 2rem;
}
.accordionSummary .accordion-button:before {
  position: absolute;
  left: 0;
  top: 2px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  content: "";
  border: #ddd 1px solid;
}
.accordionSummary .accordion-button:after {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-image: none;
  position: absolute;
  left: 4px;
  top: 6px;
}
.accordionSummary .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordionSummary {
  margin-bottom: 1.5rem;
}
.accordionSummary h4 {
  margin-bottom: 1.2rem;
}

.checkout .addCartBtn {
  width: 100%;
  font-weight: bold;
  font-size: 1.3rem;
}
.checkout .shipping-info {
  margin-top: 1rem;
}
.checkout .shipping-info {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.4s;
}
.checkout .shipping-info.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  overflow: visible;
}

.wishList .shop-table {
  text-align: start;
  width: 100%;
}
.wishList .shop-table th {
  padding: 1.7rem 0 1.3rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  line-height: 1.6;
  letter-spacing: 0;
}
.wishList .shop-table tbody td:not(:first-child),
.wishList .shop-table thead th:not(:first-child) {
  padding-left: 1rem;
}
.wishList .wishlist-table .product-price {
  width: 15.17%;
}
.wishList .wishlist-table .product-stock-status {
  width: 19.84%;
}
.wishList .wishlist-table .wishlist-action {
  width: 24.19%;
}
.wishList .shop-table td {
  padding: 2rem 0 2rem 0;
  padding-right: 0px;
  border-top: 1px solid #eee;
}
.wishList .shop-table .product-thumbnail {
  width: 11rem;
  padding-right: 1rem;
}
.wishList .shop-table .product-name {
  white-space: normal;
  word-break: break-word;
}
.wishList .shop-table .product-name a {
  color: #04041c;
}
.wishList .shop-table .product-thumbnail > div {
  display: block;
  max-width: 10rem;
  width: 100%;
}
.wishList .p-relative {
  position: relative !important;
}
.wishList .shop-table .product-thumbnail a {
  position: relative;
  display: block;
}
.wishList .shop-table .product-thumbnail a figure {
  margin: 0;
}
.wishList .shop-table .product-thumbnail a figure img {
  display: block;
  width: 100%;
}
.wishList .shop-table .btn-close {
  position: absolute;
  padding: 0;
  background: #fff;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  top: -14px;
  right: -8px;
}
.wishList .shop-table .btn-close i {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  margin-left: 0;
}

.progress-bar {
  background-color: transparent !important;
}

.contentPageWrap {
  padding: 3rem 0;
}
.contentPageWrap .contentPage h3 {
  padding: 0;
  margin-bottom: 0.8rem;
  font-size: 22px;
}
.contentPageWrap .contentPage p {
  margin-bottom: 1.5rem;
}

.errors {
  color: #a11a1a;
}

.success {
  color: #599e14;
}

.logins {
  text-align: center;
}

.owl-theme.newCourseBanSlider .owl-nav {
  margin: 0;
}
.owl-theme.newCourseBanSlider .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  font-size: 38px;
}
.owl-theme.newCourseBanSlider .owl-nav [class*=owl-] span {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.owl-theme.newCourseBanSlider .owl-nav .owl-prev {
  left: 50px;
}
.owl-theme.newCourseBanSlider .owl-nav .owl-next {
  right: 50px;
}

.newRealeaseBox {
  display: block;
}
.newRealeaseBox .newRealeaseBoxTop {
  overflow: hidden;
  border-radius: 30px 0;
}
.newRealeaseBox .newRealeaseBoxTop img {
  display: block;
  width: 100%;
  transition: all ease-in-out 0.4s;
  filter: grayscale(100%);
}
.newRealeaseBox .newRealeaseBoxBtm {
  padding: 0.8rem 0;
}
.newRealeaseBox .newRealeaseBoxBtm h4 {
  padding-bottom: 5px;
  font-size: 17px;
}
.newRealeaseBox .newRealeaseBoxBtm p {
  font-size: 12px;
  color: #666;
}
.newRealeaseBox:hover img {
  transform: scale(1.15);
  transform-origin: 0 0 0;
  filter: unset;
}

.errors {
  color: #a11a1a;
}

.success {
  color: #599e14;
}

.logins {
  text-align: center;
}

#loading_container {
  background-color: #18191a;
  position: fixed;
  transition: all 0.4s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  pointer-events: none;
}
#loading_container .custom-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
#loading_container .custom-line img {
  width: 100%;
}
#loading_container .custom-line .loader {
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.newRealeaseSec {
  padding: 3.5rem 0;
}
.newRealeaseSec [class*=col-] {
  margin-top: calc(var(--bs-gutter-x) * 0.7);
  margin-bottom: calc(var(--bs-gutter-x) * 0.7);
}
.newRealeaseSec.trandingMusic {
  background: url(../../public/images/new-realease/bg.png) bottom center no-repeat #ccc498;
  background-size: cover;
}

.playlist .playlistLeft {
  flex: 0 0 560px;
  max-width: 560px;
  background: #000;
  padding: 2rem;
}
.playlist .playlistLeft .playlistLeftInner {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
}
.playlist .playlistLeft .playlistLeftInner h5 {
  color: #cbc394;
  font-family: "Ofelia Std Book";
  font-size: 15px;
}
.playlist .playlistLeft .playlistLeftInner h3 {
  color: #fff;
  font-size: 21px;
  font-weight: 500;
}
.playlist .playlistLeft .playlistLeftInner .pliImg img {
  display: block;
  width: 100%;
}
.playlist .playlistRight {
  flex-grow: 1;
}

.pliBtm {
  margin-top: 1.5rem;
}
.pliBtm .pliBtmLeft {
  flex: 0 0 50px;
  max-width: 50px;
}
.pliBtm .pliBtmLeft span {
  display: block;
}
.pliBtm .pliBtmLeft span img {
  width: 100%;
  border-radius: 50%;
}
.pliBtm .pliBtmMid {
  flex-grow: 1;
}
.pliBtm .pliBtmMid span {
  display: block;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  padding: 0 0.8rem;
}
.pliBtm .pliBtmRight {
  flex: 0 0 50px;
  max-width: 50px;
}
.pliBtm .pliBtmRight a {
  display: block;
  background: #fff;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 5px;
  color: #000;
  font-size: 22px;
}

.playListTop {
  background: #000;
}
.playListTop ul li {
  padding: 0.8rem;
  color: #fff;
  font-weight: 600;
}

.playlist {
  border-top: #939598 1px solid;
}

.text-cente {
  text-align: center;
}

.playListBtm {
  overflow-y: auto;
  max-height: 490px;
}
.playListBtm ul li {
  padding: 0.5rem;
  color: #000;
  font-weight: 600;
}
.playListBtm ul li:first-child span {
  display: inline-block;
  width: 50px;
  margin-right: 0.5rem;
}
.playListBtm ul li:first-child span img {
  border-radius: 50%;
  width: 100%;
}
.playListBtm ul li .playNowTableBtn {
  color: #000;
  line-height: 36px;
  padding: 0 1.2rem;
  border-radius: 6px;
  background: #cbc394;
  font-weight: 600;
}
.playListBtm ul li .playNowTableBtn svg {
  margin-left: 0.3rem;
}
.playListBtm ul:nth-child(odd) {
  background: #fff;
}
.playListBtm ul:nth-child(even) {
  background: #ededed;
}

.playListTop ul li:first-child,
.playListBtm ul li:first-child {
  width: 40%;
  padding-left: 1.5rem;
}
.playListTop ul li:nth-child(2),
.playListBtm ul li:nth-child(2) {
  width: 20%;
}
.playListTop ul li:nth-child(3),
.playListBtm ul li:nth-child(3) {
  width: 20%;
}
.playListTop ul li:nth-child(4),
.playListBtm ul li:nth-child(4) {
  width: 20%;
}

.similarArtist {
  padding: 3rem 0;
}
.similarArtist h3 {
  margin-bottom: 1.5rem;
}
.similarArtist .similarArtistInner img {
  display: block;
  width: 100%;
  border-radius: 50%;
}

.owl-theme.similarArtistSlider .owl-nav {
  margin: 0;
}
.owl-theme.similarArtistSlider .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  font-size: 38px;
}
.owl-theme.similarArtistSlider .owl-nav [class*=owl-] span {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.owl-theme.similarArtistSlider .owl-nav .owl-prev {
  left: -25px;
}
.owl-theme.similarArtistSlider .owl-nav .owl-next {
  right: -25px;
}

.black {
  color: #04041c;
}

.midHeader {
  display: none;
}

.blog-thumb a {
  display: block;
}

.blog-thumb a img {
  display: block;
  width: 100%;
  height: 170px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-post-date {
  position: absolute;
  left: 10px;
  top: 10px;
  background: #fff;
  line-height: 30px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 600;
}

.blog-title {
  color: #000;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 10px;
}

.blog-title:hover {
  color: #555e69;
}

.blogText p {
  color: #555e69;
  line-height: 22px;
}

.blog-read-more {
  background: #000;
  color: #fff;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 15px;
  font-size: 15px;
  border: none;
  margin-top: 15px;
}

.blog-read-more:hover {
  background: #333;
  color: #fff;
}

.blog-list-wrap + .blog-list-wrap {
  margin-top: 1.2rem;
  padding-top: 1.2rem;
  border-top: #ddd 1px dashed;
}

.blog-title h2 {
  margin-bottom: 0;
  font-size: 28px;
}

.recentPostul li {
  margin-bottom: 15px;
}

.recentPostul li a {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.blogRecentprt {
  flex: 0 0 80px;
  max-width: 80px;
}

.blogRecentprt img {
  width: 100%;
  display: block;
  height: 66px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blogRightPrt {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
  padding-left: 10px;
}
.blogRightPrt h1, .blogRightPrt h2, .blogRightPrt h3, .blogRightPrt h4, .blogRightPrt h5 {
  font-size: 14px;
  padding-bottom: 5px;
  color: #2d2d2d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.blogRightPrt p {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 300;
}

.sticky-100 {
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 130px;
}

.blog-page-single .blog-big-thumb img {
  display: block;
  width: 100%;
}

.blog-page-single .blog-content .blog-title {
  margin-top: 5px;
}

.videoBtns {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.humbergar {
  display: none;
}

.downLoadDtnArea ul li a {
  color: #333;
  display: block;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px -1px;
  font-size: 13px;
}
.downLoadDtnArea ul li a span {
  display: block;
  font-size: 14px;
  color: #3e3e3e;
  margin-top: 0.2rem;
  font-weight: 900;
}
.downLoadDtnArea ul li a:hover {
  background: #333;
  color: #fff;
}
.downLoadDtnArea ul li a:hover span {
  color: #e0e0e0;
}

.storeBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 15px;
  background: #fff;
  border-radius: 14px;
}
.storeBox:not(:last-child) {
  margin-bottom: 1.25rem;
}
.storeBox .storeBoxLeft {
  flex: 0 0 216px;
  max-width: 216px;
  position: relative;
}
.storeBox .storeBoxLeft .discountPercent {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 11px;
  background: #cbc394;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.storeBox .storeBoxLeft span {
  display: block;
}
.storeBox .storeBoxLeft span img {
  display: block;
  width: 100%;
  border-radius: 14px 0 0 14px;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid #e5e5e5;
}
.storeBox .storeBoxLeft span.bestSeller {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 11px;
  background: #08c1b8;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.storeBox .storeBoxRight {
  flex: 0 0 calc(100% - 216px);
  padding: 0.8rem 1.25rem;
}
.storeBox .storeBoxRight .hurry {
  color: #fc0404;
  font-family: "Ofelia Std";
  font-weight: 500;
}
.storeBox .storeBoxRight .hurry p {
  padding: 0;
}
.storeBox .storeBoxRight h4 {
  font-size: 18px;
  font-family: "Ofelia Std Book";
  padding-bottom: 0;
  margin-bottom: 0.5rem;
}
.storeBox .storeBoxRight .starArea {
  margin-bottom: 0.8rem;
}
.storeBox .storeBoxRight h5 {
  font-size: 16px;
  font-family: "Ofelia Std Book";
  padding-bottom: 0;
  margin-bottom: 0.5rem;
}
.storeBox .storeBoxRight p {
  padding-bottom: 0.5rem;
}
.storeBox .ibrInner {
  flex-grow: 1;
}
.storeBox .sbPrice {
  flex: 0 0 180px;
  max-width: 180px;
  border-left: #eae9e9 1px solid;
  background: #f9f5e1;
  padding: 1rem;
}
.storeBox .sbPrice ul li {
  text-align: center;
}
.storeBox .sbPrice ul li.oldPrice {
  text-decoration: line-through;
  font-size: 1rem;
  color: #919191;
  margin-bottom: 0.5rem;
}
.storeBox .sbPrice ul li.currentPrice {
  font-size: 1.5rem;
}

.buyNowBtn {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}

.selectOptionBox {
  position: relative;
  border: #ddd 1px solid;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.selectOptionBox label {
  display: block;
}
.selectOptionBox select {
  border: none;
  background-color: transparent;
}

.listGridTopRight ul li:not(:last-child) {
  margin-right: 0.5rem;
}
.listGridTopRight ul li a {
  font-size: 1.5rem;
  color: #939598;
  display: block;
  width: 60px;
  height: 60px;
  line-height: 58px;
  text-align: center;
  border-radius: 0.5rem;
  border: #ddd 1px solid;
}
.listGridTopRight ul li a.active, .listGridTopRight ul li a:hover {
  color: #000404;
  background: #cbc394;
}

.listGridTop {
  margin-bottom: 1.5rem;
}
.listGridTop .listGridTopLeft h2 {
  padding-bottom: 0;
  font-size: 32px;
}

.storeSection {
  padding: 3rem 0;
}
.storeSection .projectInnerMid h3 {
  font-size: 1.1rem;
}
.storeSection .projectInnerMid h3 a {
  color: #000;
}
.storeSection .totalUpdate {
  margin-top: 0.8rem;
}
.storeSection .totalUpdate ul li {
  font-size: 1.3rem;
}
.storeSection .totalUpdate ul li .line-through {
  color: #919191;
}

.storeAcordian {
  position: sticky;
  top: 140px;
}
.storeAcordian .accordion-item {
  margin-bottom: 1rem;
}
.storeAcordian .accordion-button {
  color: #212529;
  background-color: #cbc394;
  box-shadow: none !important;
  border-radius: 0.5rem 0.5rem 0 0;
}
.storeAcordian .accordion-item {
  border-radius: 0.5rem;
}
.storeAcordian .accordion-header {
  padding: 0;
}
.storeAcordian .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #cbc394;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.react-slider__btnPrev, .react-slider__btnNext {
  display: none;
}

.blogSection {
  padding: 3rem 0;
}

.navigation .signUpLang {
  display: none;
}

.teacherImg img {
  width: 100%;
  display: block;
}

.teacherArea {
  padding: 3rem 0;
}
.teacherArea .teacherAge {
  font-size: 20px;
}

.bookLesson {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.6;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}

.teacherDesc ul li a {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 10px -5px;
  padding: 0.8rem 1rem;
  border-radius: 10px;
  background: #f2f2f2;
  border: #eee 1px solid;
  color: #333;
  height: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.teacherDesc ul li a:hover {
  background: #676565;
  color: #fff;
}

.thankYouSection {
  padding: 50px 0;
  margin-bottom: 50px;
  background: #f7f7f7;
  border-top: 1px solid #f0f0f0;
}
.thankYouSection .text-style-design .text-icon {
  display: block;
  font-size: 60px;
}
.thankYouSection .text-style-design.thank-you .text-icon {
  color: green;
}
.thankYouSection .text-style-design.rejeted .text-icon {
  color: red;
}
.thankYouSection .text-style-design p {
  margin-top: 15px;
  font-size: 22px;
}
.thankYouSection .text-style-design .continueBtn {
  line-height: 2.5;
  padding: 0 20px;
  background: #000;
  color: #fff;
  margin-top: 20px;
  border-radius: 5px;
}

.lesson-price li {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 10px -5px;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  background: #f2f2f2;
  border: #eee 1px solid;
  color: #333;
  margin: 0.3rem 0;
}
.lesson-price li:not(:last-child) {
  margin-right: 15px;
}

.details-box-corse ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.details-box-corse ul li {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 10px -5px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: #f2f2f2;
  border: #eee 1px solid;
  color: #333;
  height: 100%;
}
.details-box-corse ul li:not(:last-child) {
  margin-right: 15px;
}

.course-ratting-box {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 10px -5px;
  padding: 1rem;
  border-radius: 10px;
  background: #f7f7f7;
  min-height: 170px;
  margin: 0.7rem 0;
}
.course-ratting-box .course-ratting ul li {
  color: gold;
}
.course-ratting-box .course-ratting ul li:not(:last-child) {
  margin-right: 5px;
}
.course-ratting-box h5 {
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 500;
}
.course-ratting-box p.clr-grey {
  color: #a7a7a7;
  font-size: 13px;
}
.course-ratting-box .course-ratting-desc p span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  color: #a7a7a7;
  margin-top: 5px;
}

.book-lson-modal .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.book-lson-modal .modal-header .modal-title {
  padding-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #787878;
}
.book-lson-modal .modal-header .modal-title span {
  color: #000;
}
.book-lson-modal .modal-header .btn-close {
  background: none;
  position: absolute;
  padding: 0;
  top: 10px;
  right: 15px;
  font-size: 16px;
  color: #f00;
  opacity: 1;
}
.book-lson-modal .book-lson-slt {
  margin-bottom: 10px;
}
.book-lson-modal .book-lson-slt label {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 1;
}
.book-lson-modal .book-lson-slt .lson-select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: rgb(255, 255, 255) url(../images/down-arrow.png) center right 10px no-repeat;
  background-size: 13px;
  padding-right: 25px;
  border: #eee 1px solid;
  width: 100%;
  height: 40px;
}
.book-lson-modal .lsono-dtls h5 {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 1;
}

.lson-choose li {
  margin-bottom: 6px;
}
.lson-choose li input[type=radio] {
  display: none;
}
.lson-choose li input[type=radio] + label {
  color: #000;
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.lson-choose li input[type=radio]:checked + label {
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.lson-choose li:not(:last-child) {
  margin-right: 6px;
}

.lsono-price-next {
  border-top: 1px solid #d9d9d9;
}
.lsono-price-next li span {
  font-size: 25px;
}

.lson-checkout-list {
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
}
.lson-checkout-list + .lson-checkout-list {
  margin-top: 10px;
}
.lson-checkout-list .lson-checkout-btn-wrap {
  border-top: 1px solid #ebebeb;
  margin-top: 10px;
  padding-top: 10px;
}
.lson-checkout-list .lson-checkout-btn-wrap ul li:not(:last-child) {
  margin-right: 6px;
}

.lson-checkout-wrap .lson-checkout-lft {
  flex: 0 0 100px;
  max-width: 100px;
  margin-right: 15px;
}
.lson-checkout-wrap .lson-checkout-lft .lson-checkout-img img {
  width: 100%;
  display: block;
}
.lson-checkout-wrap .lson-checkout-rgt {
  flex: 1 0 0%;
}
.lson-checkout-wrap .lson-checkout-rgt .lson-checkout-text h4 {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}
.lson-checkout-wrap .lson-checkout-rgt .lson-checkout-text p {
  color: #000;
}
.lson-checkout-wrap .lson-checkout-rgt .lson-checkout-text p span {
  font-size: 25px;
}

.schduleLesson {
  border-radius: 5px;
  background: #000;
  color: #fff !important;
  line-height: 2.2;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}

.deletLesson {
  border-radius: 5px;
  background: #f00;
  color: #fff !important;
  line-height: 2.2;
  padding: 0 1.6rem;
  border: none;
  display: inline-block;
  border-radius: 50px;
}

.leson-check-sec {
  padding: 80px 0;
}

.projectInnerFixFooter {
  padding-bottom: 74px;
}
.projectInnerFixFooter .addCartArea {
  position: absolute;
  bottom: 0;
  left: 0;
}

.projectInnerFixFooter-2 {
  padding-bottom: 58px;
}
.projectInnerFixFooter-2 .coursesBtnArea {
  position: absolute;
  bottom: 0;
  left: 0;
}

.projectInnerFixFooter-2 .projectInnerTop a img {
  -o-object-position: top;
     object-position: top;
}

.fsbInner {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 60px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px -10px;
  border-radius: 20px;
}
.fsbInner h3 {
  padding: 0;
  margin-bottom: 25px;
  text-align: center;
}
.fsbInner table td {
  vertical-align: top;
}

.fsbInner table td img {
  width: 80px;
  display: block;
}
.fsbInner table td h5 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  font-family: "Ofelia Std";
}

.w-90 {
  width: 90px;
}

.fsbInnerArea {
  border-radius: 6px;
  padding: 10px;
  background: #f4f4f4;
  border: #eeebeb 1px solid;
}

.noWrap {
  white-space: nowrap;
}

.finalStepBooking {
  padding: 3rem 0;
}

.fsbInnerTotal {
  margin-top: 25px;
}
.fsbInnerTotal .totalOrder {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}
.fsbInnerTotal .totalOrder h6 {
  font-size: 16px;
  font-weight: bold;
}
.fsbInnerTotal .totalOrder p {
  color: #08c1b8;
  font-weight: bold;
}

.lbModal .modal-title.h4 span {
  display: block;
  font-size: 14px;
  color: #0c68db;
  font-weight: bold;
}
.lbModal .lbModalSelect label {
  margin-bottom: 5px;
}

.red {
  color: red;
}

.vimeoVideoSec {
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}
.vimeoVideoSec .vimeoVideoWrap {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.5) 0 4px 21px 0px;
}
.vimeoVideoSec .vimeoVideoWrap iframe {
  width: 100%;
  max-width: 700px;
  height: 315px;
  display: block;
}
.vimeoVideoSec:before {
  content: "";
  width: 100%;
  height: 75%;
  background: #ddddd2;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

.onlyYoutube {
  background: #acacac;
  height: 250px;
}
.onlyYoutube .playIcon {
  color: #fff;
}
.onlyYoutube img {
  display: block;
  width: 100%;
}
.onlyYoutube .modal-video-body .modal-video-movie-wrap iframe {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.fcboxLeft .discountPercent {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 11px;
  background: #08c1b8;
  color: #fff;
  border-radius: 30px;
  line-height: 2.4;
  padding: 0 0.8rem;
  display: block;
  font-family: "Ofelia Std";
  font-weight: 500;
}

.star-cb-group {
  /* remove inline-block whitespace */
  font-size: 0;
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  /* the hidden clearer */
}

.star-cb-group * {
  font-size: 1.5rem;
}

.star-cb-group > input {
  display: none;
}

.star-cb-group > input + label {
  /* only enough room for the star */
  display: inline-block;
  overflow: hidden;
  text-indent: 9999px;
  width: 1em;
  white-space: nowrap;
  cursor: pointer;
}

.star-cb-group > input + label:before {
  display: inline-block;
  text-indent: -9999px;
  content: "☆";
  color: #888;
}

.star-cb-group > input:checked ~ label:before, .star-cb-group > input + label:hover ~ label:before, .star-cb-group > input + label:hover:before {
  content: "★";
  color: #e52;
  text-shadow: 0 0 1px #333;
}

.star-cb-group > .star-cb-clear + label {
  text-indent: -9999px;
  width: 0.5em;
  margin-left: -0.5em;
}

.star-cb-group > .star-cb-clear + label:before {
  width: 0.5em;
}

.star-cb-group:hover > input + label:before {
  content: "☆";
  color: #888;
  text-shadow: none;
}

.star-cb-group:hover > input + label:hover ~ label:before, .star-cb-group:hover > input + label:hover:before {
  content: "★";
  color: #e52;
  text-shadow: 0 0 1px #333;
}

fieldset {
  border: 0;
  /* background: #222; */
  /* width: 5em; */
  border-radius: 1px;
  padding: 0 0 0.9em;
  /* margin: 1em auto; */
}

#log {
  margin: 1em auto;
  width: 5em;
  text-align: center;
  background: transparent;
}

.player {
  width: 480px;
  height: 320px;
}

.top-currency .selectCurrency {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: rgb(255, 255, 255) url(../../public/images/select-angle2.png) center right -8px no-repeat;
  background-size: 32px;
  width: 100%;
  height: 36px;
  padding: 0 18px 0 10px;
  border: none;
  font-weight: 600;
  outline: none !important;
  box-shadow: none;
  font-size: 14px;
}
.top-currency .selectlanguage {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: rgb(255, 255, 255) url(../../public/images/svg/globe.svg) center right 1px no-repeat;
  background-size: 35px;
  width: 100%;
  height: 36px;
  padding: 0 40px 0 10px;
  border: none;
  font-weight: 600;
  outline: none !important;
  box-shadow: none;
  font-size: 14px;
}

.react-slider__ul li.active {
  height: 60px;
}

.download-file {
  margin-top: 15px;
}
.download-file h4 {
  font-size: 15px;
  padding: 0 0 8px;
}
.download-file ul li a {
  color: #000;
}
.download-file ul li a img {
  width: 15px;
  display: inline-block;
  position: relative;
  top: -3px;
  margin-left: 10px;
}
.download-file ul li:not(:last-child) {
  margin-bottom: 6px;
}

.buttonV {
  border: none;
  background: #333;
  color: #fff;
  padding: 9px 20px;
  border-radius: 6px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 7px 14px 7px 0;
}

.buttonV.active {
  background: #1cc216;
  color: #fff;
}

.buttonV.danger {
  background: #f01b1b;
  color: #fff;
}

.buttonJoin {
  border: none;
  background: #333;
  color: #fff;
  padding: 8px 25px;
  border-radius: 6px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  display: block;
  width: 100%;
}

.lson-checkout-list.lclMd .lson-checkout-wrap .lson-checkout-lft {
  flex: 0 0 186px;
  max-width: 186px;
}
.lson-checkout-list.lclMd .lson-checkout-wrap .lson-checkout-rgt .lson-checkout-text h4 {
  font-size: 20px;
  padding-bottom: 10px;
}
.lson-checkout-list.lclMd .lson-checkout-wrap .lson-checkout-rgt .lson-checkout-text p {
  margin-bottom: 10px;
}

.individual-users .studentWrap {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 15px;
}
.individual-users .studentWrap .students {
  flex: 0 0 100%;
  max-width: 100%;
}
.individual-users .studentWrap .students:not(:last-child) {
  margin-bottom: 15px;
}
.individual-users .bigVideo {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  border-radius: 20px;
  overflow: hidden;
}
.individual-users video {
  border-radius: 20px;
}

.videoSection {
  padding: 30px 0;
  background: #000;
  color: #fff;
  min-height: 250px;
  position: relative;
}
.videoSection h5, .videoSection h4 {
  color: #fff;
}
.videoSection p {
  color: #fff !important;
}

.text-style-design-22 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-style-design-22 h1 {
  color: #fff;
}
.text-style-design-22 .continueBtn {
  line-height: 2.5;
  padding: 0 20px;
  background: #fff;
  color: #000;
  margin-top: 20px;
  border-radius: 5px;
}

.custom-confirmation-box {
  background: #11180c;
  padding: 40px;
  border-radius: 10px 0;
  text-align: center;
  width: 350px;
}
.custom-confirmation-box h1 {
  color: #78bc4c;
  font-size: 34px;
  margin-bottom: 8px;
}
.custom-confirmation-box p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
}
.custom-confirmation-box .no-btn,
.custom-confirmation-box .yes-btn {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  border: none;
  outline: none !important;
  box-shadow: none;
  margin: 3px 6px;
  color: #fff;
  font-size: 14px;
}
.custom-confirmation-box .no-btn {
  background-color: #dc3545;
}
.custom-confirmation-box .yes-btn {
  background-color: #78bc4c;
}

.PhoneInputInput {
  border: none !important;
  outline: none;
}

.piArea .PhoneInput {
  padding: 1.2rem 1.4rem !important;
}

.react-tel-input .form-control {
  height: calc(3.5rem + 2px) !important;
}

.signUpLang ul li ul.dropDownReg {
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 10px -5px;
  z-index: 2;
  width: 130px;
  border-radius: 0 0 16px 16px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.4s;
}
.signUpLang ul li ul.dropDownReg li {
  padding: 0;
  margin-right: 0;
}
.signUpLang ul li ul.dropDownReg li:not(:last-child) {
  border-bottom: #ddd 1px solid;
}
.signUpLang ul li ul.dropDownReg li a {
  background-color: transparent;
  color: #545454;
  padding: 0 15px;
  line-height: 42px;
}
.signUpLang ul li:hover ul.dropDownReg {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.msg-wrappage {
  width: 100%;
}
.msg-wrappage .msg-history {
  /* height: 64vh; */
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 10px;
}
.msg-wrappage .sent-msg, .msg-wrappage .received-msg {
  min-width: 100px;
}
.msg-wrappage .sent-msg {
  margin-right: 15px;
}
.msg-wrappage .sent-msg p {
  background: #ddd;
  border-radius: 3px;
  font-size: 13px;
  margin: 0;
  color: #2b2b2b !important;
  padding: 5px 10px 5px 12px;
  width: 100%;
  text-align: right;
  font-weight: bold;
}
.msg-wrappage .sent-msg .time-date {
  text-align: right;
}
.msg-wrappage .time-date {
  color: #8e875c;
  display: block;
  font-size: 10px;
  margin: 0;
}
.msg-wrappage .user-img img {
  display: block;
  height: 32px;
  width: 32px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.type-msg-wrap {
  padding: 10px 7px 10px 10px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

.input-msg-write {
  position: relative;
}
.input-msg-write .write-msg {
  border: 1px solid #c4c4c4;
  height: 50px;
  resize: none;
  font-size: 14px;
  padding-right: 50px;
}
.input-msg-write .msg-send-btn {
  background: #00aeef;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  width: 33px;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.studentWrap-2 {
  transition: all ease-in-out 0.4s;
  position: absolute;
  height: 100%;
  width: 30%;
  background: #f2f2f2;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.videoSection.pr-30p {
  padding-right: calc(30% + 15px);
  min-height: 31vw;
}
.videoSection.pr-30p .studentWrap-2 {
  transform: translateX(0);
}

.videoSection {
  transition: all ease-in-out 0.4s;
  overflow-x: hidden;
}

.masgTop {
  padding: 18px 15px;
  background: #ddd;
}
.masgTop h3 {
  padding: 0 !important;
  font-size: 20px;
}

.closeMsg {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #666;
  border-radius: 50%;
  font-weight: bold;
}
.closeMsg:hover {
  background-color: #333;
}

.box-detailsWrap {
  background: #f2f2f2;
  position: relative;
}

.msg-wrap-main {
  background: #fff;
}

.add-copybtn {
  position: relative;
  margin-bottom: 15px;
}
.add-copybtn .add-copybtn-input {
  padding-right: 100px;
}
.add-copybtn .copy-btn-wrap {
  position: absolute;
  right: 0;
  top: 0;
}
.add-copybtn .copy-btn-wrap .addCartBtn {
  line-height: 38px;
  border-radius: 0;
}

.rtl * {
  text-align: right;
}

.rtl .text-center,
.rtl .text-center *,
.rtl .testiInner * {
  text-align: center;
}

.rtl .testimonials .owl-prev span, .rtl .testimonials .owl-next span {
  text-align: center;
}

.rtl .topRatedMusic .boxImg span {
  left: auto;
  right: 20px;
}

.rtl .addBanner .addBannerDesc::after {
  left: 0;
  right: auto;
}

.rtl .addBanner .addBannerDesc .addBannerDesc::after {
  left: auto;
  right: -100px;
}

.rtl .subscribeWrap .subscribeInner .subsSrc .form-control {
  padding: 0 30px 0 158px;
}

.rtl .subscribeWrap .subscribeInner .subsSrc .subscribeBtn {
  left: 0;
  right: auto;
  border-radius: 50px 0 0 50px;
}

.rtl .topLinks ul li a {
  font-size: 17px;
}

.rtl .coursesBtnArea .coursesBtn {
  font-size: 14px;
}

body.rtl {
  font-size: 17px;
}

.rtl .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(1rem);
}

.rtl .form-floating > .form-control:focus ~ label,
.rtl .form-floating > .form-control:not(:placeholder-shown) ~ label,
.rtl .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(1rem);
}

.rtl .regWrap .form-floating > label {
  left: auto;
}

.rtl .react-tel-input .form-control {
  padding-left: 20px;
  padding-right: 68px;
}

.rtl .react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}

.rtl .react-tel-input .selected-flag {
  left: -11px;
}

.rtl .abc-radio label::before {
  left: 9px;
  margin-left: 0;
}

.rtl .abc-radio label::after {
  left: 32px;
}

.rtl .regWrap .radioWrap ul li:not(:last-child) {
  margin-left: 20px;
}

.rtl .blogRightPrt {
  padding-left: 0;
  padding-right: 15px;
}

.rtl .popHoverBox .phList ul li::before {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}

.rtl .popHoverBox .phList ul li {
  padding-left: 0;
  padding-right: 30px;
}

.rtl .me-2 {
  margin-right: unset;
  margin-left: 0.5rem !important;
}

.rtl .owl-theme.newCourseBanSlider .owl-nav [class*=owl-] span {
  top: 61%;
  transform: translate(-50%, -50%) rotate(180deg);
  height: 30px;
  line-height: 30px;
}

.rtl .banner .bannSlogan h2 span.everyOne {
  display: block;
  text-align: center;
}

.rtl .courseIncludes ul li {
  padding-left: 0;
  padding-right: 1.9rem;
}

.rtl .courseIncludes ul li span {
  left: auto;
  right: 0;
}

.rtl .cdAccordion .accordion-button {
  padding: 0.7rem 3rem 0.7rem 1.25rem;
}

.rtl .cdAccordion .prvTime ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 20px;
}

.rtl .cdAccordion .accordion-button::after {
  left: auto;
  right: 13px;
}

.rtl .whatYouLearn ul li {
  padding-right: 25px;
  padding-left: 0;
}

.rtl .whatYouLearn ul li::before {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}

.rtl .requirements p::after {
  left: auto;
  right: 0;
}

.rtl .requirements p {
  padding-left: 0;
  padding-right: 28px;
}

.rtl .frBox .frBoxDesc {
  padding-left: 0;
  padding-right: 1.25rem;
}

.rtl .studentFeedback .pbArea .pbWrap .pbWrapRight {
  padding-left: 0;
  padding-right: 1.25rem;
}

.rtl .Login .form-floating > label {
  left: auto;
  right: 0;
}

.rtl .lesson-price li:not(:last-child) {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .details-box-corse ul li:not(:last-child) {
  margin-right: unset;
  margin-left: 15px;
}

.fontSize14 {
  font-size: 13px;
}

.orderSummary td {
  vertical-align: middle;
}
.orderSummary td.fontBold18 {
  font-family: "Ofelia Std";
  font-weight: bold;
  font-size: 18px;
}

.productImgMd {
  width: 150px;
}
.productImgMd img {
  display: block;
  width: 100%;
}

.orderSummaryTop {
  background: #f2f2f2;
  padding: 15px;
  margin-bottom: 15px;
}
.orderSummaryTop h5 {
  padding: 0;
  font-weight: normal;
  font-size: 18px;
}

.default-banner img {
  display: block;
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}

.rtl .zoomerArea .producDetailRight .pdrTop ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 0.8rem;
}
.rtl .zoomerArea .producDetailRight .pdrTop ul li.ratingBar {
  margin-left: 0;
  margin-right: 0.8rem;
}
.rtl .zoomerArea .producDetailRight .pdrTop ul li.ratingBar::after {
  left: auto;
  right: -12px;
}
.rtl .sbPrice .addCartBtn {
  padding: 0 1rem;
  font-size: 14px;
}
.rtl .listGridTopRight ul li:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
.rtl .cart-item-product-wrap {
  margin-left: 0;
  margin-right: 30px;
}
.rtl .cart-totals .c-order-total .cart-price {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .controls2,
.rtl .qtyInput2 {
  text-align: center;
}
.rtl .cart-items tr th.first {
  text-align: right;
}
.rtl .owl-theme.newHomeTestimonialSlider .owl-nav [class*=owl-] span {
  transform: rotate(180deg);
}

.bg-overlay .react-parallax-content {
  z-index: 1;
}
.bg-overlay .react-parallax-content::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.book-lesson-box {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}
.book-lesson-box h6 {
  padding-bottom: 0.2rem;
  line-height: 1;
}
.book-lesson-box h6:not(:first-child) {
  margin-top: 0.6rem;
}
.book-lesson-box p {
  line-height: 1;
  font-size: 14px;
}

.final-lesson-book {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}
.final-lesson-book p {
  line-height: 1;
  font-size: 12px;
}
.final-lesson-book p + p {
  margin-top: 0.4rem;
}

.videoBtnWrapper {
  font-size: 30px;
  margin: 8px 0 0 0;
}
.videoBtnWrapper:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .playlist .playlistLeft {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .playlist .playlistRight {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
@media only screen and (max-width: 1350px) {
  .owl-theme.similarArtistSlider .owl-nav .owl-next {
    right: 5px;
  }
  .owl-theme.similarArtistSlider .owl-nav .owl-prev {
    left: 5px;
  }
}
@media only screen and (max-width: 1280px) {
  .subscribeWrap .subscribeLeft h2 {
    font-size: 32px;
  }
  .subscribeWrap .subscribeLeft h5 {
    font-size: 22px;
  }
  .ph-40 {
    padding: 0 20px;
  }
  header .topHeader .logo {
    width: 148px;
  }
  header .topHeader .topLinks ul li a {
    font-size: 12px;
    padding: 0 8px;
    line-height: 26px;
  }
  .signUpLang ul li:not(:last-child) {
    margin-right: 0.5rem;
  }
  .signUpLang ul li a {
    font-size: 12px;
  }
  .signUpLang ul li a.logInBtn {
    line-height: 36px;
    padding: 0 15px;
  }
  .signUpLang ul li a.logInBtn .svg-inline--fa {
    font-size: 14px;
  }
  .signUpLang ul li a.signUpBtn {
    line-height: 36px;
    padding: 0 15px;
  }
  .signUpLang ul li a.signUpBtn .svg-inline--fa {
    font-size: 14px;
  }
  .signUpLang ul li a img {
    width: 30px;
  }
  .signUpLang ul li.cartIcon {
    position: relative;
  }
  .signUpLang ul li.cartIcon a span {
    position: absolute;
    background: #000;
    color: #fff;
    font-size: 10px;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    left: 0;
    bottom: -3px;
  }
}
@media only screen and (max-width: 1204px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
    width: 100%;
  }
  header .topHeader .logo {
    width: 120px;
  }
  .signUpLang ul li .hSrcBtn {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1135px) {
  .testimonials .testiInner .testiInnerBtm:after, .testimonials .testiInner .testiInnerBtm:before {
    display: none;
  }
  .navigation .signUpLang {
    display: block;
    margin-right: 0;
  }
  .navigation .signUpLang ul {
    display: flex !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navigation .signUpLang ul li {
    margin-bottom: 0 !important;
    margin-right: 1rem !important;
  }
  .navigation .signUpLang ul li a, .navigation .signUpLang ul li .logInBtn {
    background: none;
    border: none;
    color: #fafafa;
    padding: 0 7px;
  }
  .navigation .signUpLang ul li a span, .navigation .signUpLang ul li .logInBtn span {
    display: none;
  }
  .navigation .signUpLang ul li a .svg-inline--fa, .navigation .signUpLang ul li .logInBtn .svg-inline--fa {
    font-size: 20px;
  }
  .navigation .signUpLang ul li a img, .navigation .signUpLang ul li .logInBtn img {
    width: 23px;
    filter: invert(100%) contrast(50);
  }
  .navigation .signUpLang ul li a.signUpBtn {
    background: none;
    border: none;
    color: #fafafa;
    padding: 0 7px;
  }
  .navigation .signUpLang ul li a.signUpBtn span {
    display: none;
  }
  .navigation .signUpLang ul li a.signUpBtn .svg-inline--fa {
    font-size: 20px;
  }
  .signUpLang {
    margin-right: 48px;
  }
  .signUpLang ul li.cartIcon a span {
    display: block !important;
    display: block !important;
    position: absolute;
    background: #eab57d;
    color: #171717;
    font-size: 10px;
    left: auto;
    bottom: 15px;
    right: 0;
  }
  .topRatedMusic .boxImg {
    height: 250px;
  }
  .humbergar {
    display: block;
  }
  .navigation {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 400px;
    right: -100%;
    top: 0;
    z-index: 5;
    transition: all ease-in-out 0.7s;
  }
  .navigation:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    z-index: -1;
    transition: all ease-in-out 0.7s;
    transition-delay: 1s;
    transform: translateX(100%);
  }
  .navigation:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(79, 80, 80, 0.8);
    top: 0;
    z-index: -1;
    transition: all ease-in-out 0.5s;
    transition-delay: 0.5s;
    transform: translateX(150%);
    transform-origin: 100% 50% 0px;
  }
  .navigation ul {
    transition: all ease-in-out 0.7s;
    transition-delay: 1.3s;
    transform: translateX(150%);
    display: block !important;
    padding: 3rem;
  }
  .navigation.active {
    right: 0;
  }
  .navigation.active:after, .navigation.active:before {
    transform: translateX(0);
  }
  .navigation.active ul {
    transform: translateX(0);
  }
  .navClose {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    color: #fff;
    border: 2px solid #fff;
    height: 40px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.25rem;
  }
  .navClose:hover {
    background: #fff;
    color: #000;
  }
  header .topHeader {
    padding: 0.3rem 0;
  }
  header .btmHeader ul li:not(:last-child) {
    margin-bottom: 1rem;
  }
  header .btmHeader ul li a {
    font-size: 1rem;
    display: block;
    line-height: 1.5 !important;
  }
  header .btmHeader ul li a:hover {
    color: #cbc394;
  }
  .forDesktop {
    display: none !important;
  }
  .midHeader {
    display: block !important;
  }
  .mbHide {
    display: none !important;
  }
  .cdInner h2 {
    font-size: 25px;
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .topRatedMusic .boxImg {
    height: 300px;
  }
}
@media only screen and (max-width: 1000px) {
  .storeBoxWrap {
    margin: 0 -0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .storeBoxWrap .storeBox {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0.5rem;
    box-shadow: none;
  }
  .storeBoxWrap .storeBox .storeBoxLeft {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .storeBoxWrap .storeBox .storeBoxRight {
    flex-basis: 100%;
    flex-wrap: wrap;
    padding: 0.8rem 0;
  }
  .storeBoxWrap .storeBox .sbPrice {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .storeBoxWrap .storeBox .sbPrice ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .storeBoxWrap .storeBox .sbPrice ul li.oldPrice {
    margin: 0 0 0 1rem;
    order: 3;
  }
}
@media only screen and (max-width: 910px) {
  .addBanner .addBannerDesc .addBannerDesc h2 {
    font-size: 40px;
  }
  .addBanner .addBannerDesc .addBannerDesc h2 span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .banner .bannSlogan h2 {
    font-size: 40px;
  }
  .topRatedMusic .boxImg {
    height: 200px;
  }
  .topRatedMusic .boxImg span {
    font-size: 17px;
  }
  .sectionTitle h2 {
    font-size: 30px;
  }
  .topRatedMusic,
  .coursesBlock {
    padding: 1rem 0;
  }
  .testimonials .testiInner .testiInnerBtm:after, .testimonials .testiInner .testiInnerBtm:before {
    font-size: 70px;
  }
  .testimonials .testiInner .testiInnerBtm:after {
    top: -23px;
  }
  .testimonials .testiInner .testiInnerBtm:before {
    content: "“";
    bottom: -23px;
  }
  .addBanner .addImg {
    width: 200px !important;
    margin: 0 auto 3rem;
  }
  .courseMenu {
    margin-bottom: 0.6rem;
  }
  .courseMenu ul li a {
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
  }
  .Login {
    padding: 3rem 0;
  }
  .Login .regWrap {
    flex: 0 90%;
    max-width: 100%;
    margin: 0 auto;
  }
  .Login .regWrap h2 {
    font-size: 21px;
  }
  .Login .regWrap h6 {
    font-size: 10px;
  }
  .whatYouLearn h3 {
    font-size: 28px;
  }
  .courseContent h3 {
    font-size: 28px;
  }
  .requirements h3 {
    font-size: 28px;
  }
  .studentsBought h3 {
    font-size: 28px;
  }
  .boughtTogether h3 {
    font-size: 28px;
    padding-bottom: 1rem;
  }
  .Login {
    padding: 0.8rem 0;
  }
  .Login .loginWrap {
    width: 94%;
  }
  .Login .loginWrap .LoginInner {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .owl-theme.newCourseBanSlider .owl-nav [class*=owl-] {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
  .playListBtm ul li .playNowTableBtn {
    padding: 0 0.6rem;
  }
  .playlist .playlistLeft {
    display: none !important;
  }
  .playlist .playlistRight {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sectionTitle h2 {
    font-size: 25px;
  }
  .fcbox .fcboxLeft {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .fcbox .fcboxRight {
    flex: 1 0;
    padding: 1rem;
  }
  .popularTopic {
    padding: 1rem 0;
  }
  .featuredCourses {
    padding: 1rem 0;
  }
  .sectionTitle.d-flex.mb-4 {
    margin-bottom: 0 !important;
  }
  .mb-4 {
    margin-bottom: 0.5rem !important;
  }
  .blog-content {
    padding-top: 0.8rem;
  }
  .blog-title {
    margin: 0 0 5px;
  }
  .listGridTop {
    margin-top: 1.5rem;
  }
  .listGridTop .listGridTopLeft h2 {
    font-size: 22px;
  }
  .projectInner .projectInnerMid p {
    font-size: 12px;
  }
  .storeSection .projectInnerMid h3 {
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  .storeSection {
    padding: 1rem 0;
  }
  .storeSection .storeAcordian {
    position: static;
    top: 0;
  }
  .cart-image {
    max-width: 34px;
  }
  .cart-title {
    color: #141414;
    font-size: 10px;
  }
  .cart-title a {
    color: #141414;
    font-size: 10px;
  }
  .qtyInput2 {
    border: 1px solid #e6e8ee;
    font-size: 11px;
    height: 34px;
    width: 40px;
  }
  .controls2 {
    border: 1px solid #e6e8ee;
    font-size: 20px;
    height: 34px;
    line-height: 16px;
    width: 30px;
  }
  .cart-variant, .cart-vendor {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-remove {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-product-wrap {
    margin-left: 8px;
  }
}
@media only screen and (max-width: 667px) {
  .cdInner {
    padding: 1.5rem 0;
    color: #fff;
  }
  .cdInner .cdInnerBox {
    padding-top: 0.8rem;
  }
  .listGridTop .listGridTopLeft h2 {
    margin-bottom: 1rem;
  }
  .banner .bannSlogan h2 {
    font-size: 30px;
  }
  .newRealeaseSec {
    padding: 1.5rem 0 0;
  }
}
@media only screen and (max-width: 600px) {
  .cdAccordion {
    margin: 0.5rem 0;
  }
  .whatYouLearn {
    margin-bottom: 0;
  }
  .whatYouLearn h3 {
    font-size: 21px;
    padding-bottom: 0.6rem;
  }
  .courseContent h3 {
    font-size: 21px;
    padding-bottom: 0;
  }
  .cdAccordion .accordion-body {
    padding: 0 1.25rem;
  }
  .requirements h3 {
    font-size: 21px;
    padding-bottom: 0.6rem;
  }
  .description h3 {
    font-size: 21px;
  }
  .studentsBought h3 {
    font-size: 21px;
  }
  .reviews h3 {
    font-size: 21px;
  }
  .sectionTitle h2 {
    font-size: 21px;
  }
  .zoomerArea .producDetailRight h2 {
    font-size: 17px;
  }
  .boughtTogether h3 {
    font-size: 21px;
  }
  .description p:not(:last-child) {
    margin-bottom: 0.8rem;
  }
  .requirements,
  .description {
    margin-bottom: 1.25rem;
  }
  .featrueReview h3 {
    font-size: 21px;
    padding-bottom: 0.8rem;
  }
  footer .footerTop {
    padding: 1rem 0;
  }
  .blog-title {
    font-size: 16px;
    margin: 0 0 5px;
  }
  .blog-read-more {
    line-height: 34px;
    font-size: 12px;
    margin-top: 10px;
  }
  .inputArea {
    margin-top: 1rem;
  }
  .zoomerArea .producDetailRight .totalUpdate .prOffer {
    margin-top: 1rem;
  }
  .playListBtm ul li {
    position: relative;
  }
  .playListBtm ul li:first-child {
    padding-left: 55px;
  }
  .playListBtm ul li:first-child span {
    width: 34px;
    position: absolute;
    left: 15px;
    margin: 0;
    top: 6px;
  }
}
@media only screen and (max-width: 575px) {
  .courseDtlsSection {
    padding: 1.5rem 0;
  }
  footer .col-sm-6:not(:last-child) {
    margin-bottom: 1rem;
  }
  .starArea ul li {
    font-size: 11px;
  }
  .coursesBtnArea .coursesBtn {
    padding: 5px 9px;
  }
  .subscribeWrap .subscribeLeft h2 {
    font-size: 22px;
  }
  .subscribeWrap .subscribeLeft h5 {
    font-size: 16px;
  }
  .subscribeWrap .subscribeInner .subsSrc .form-control {
    height: 48px;
    padding: 0 132px 0 20px;
  }
  .subscribeWrap .subscribeInner .subsSrc .subscribeBtn {
    font-size: 16px;
    line-height: 48px;
    width: 132px;
  }
}
@media only screen and (max-width: 480px) {
  header .topHeader .logo {
    width: 90px;
  }
  .signUpLang ul li .hSrcBtn {
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  .top-currency .selectlanguage {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-size: 22px;
    padding: 0 27px 0 10px;
    font-size: 14px;
  }
  .humbergar {
    top: 10px;
  }
  .regWrap .width-auto {
    padding: 0 2.6rem;
  }
  .comboBox .comboBoxImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .comboBox .comboBoxImg span img {
    border-radius: 10px;
  }
  .comboBox .comboBoxDesc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .reviews .reviewBoxIcon {
    flex: 0 0 60px;
    max-width: 60px;
  }
  .reviews .reviewBoxIcon span {
    font-size: 12px;
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
  .addBuy {
    margin-top: 2.25rem;
  }
  .addBuy ul li a,
  .addBuy ul li .buyNowShop {
    font-size: 14px;
    padding: 0 1.5rem;
  }
}/*# sourceMappingURL=style.css.map */