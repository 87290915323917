header {
  width: 100%;
  z-index: 3;
  transition: all .4s ease-in-out;
  background: #fff;
  border-bottom: #ddd 1px solid;
  top:-200px;
  .topHeader{
    padding: .8rem 0;
    .logo {
      width: 180px;
      a{
        display: block;
        img{
          display: block;
          width: 100%;
        }
      }
    }
    
  }
  
  .btmHeader {
    background: $bg-1;
    ul{
      li{
        a {
          color: $bg-white;
          font-size: 14px;
          line-height: 52px;
          font-family: 'Ofelia Std';
          font-weight: 500;
        }
      }
    }
  }
  &.sticky{
    .topHeader{
      padding: .3rem 0;
    }
    .btmHeader ul li a {
      line-height: 46px;
    }
  }
  
}

.topLinks{
  padding: 0 1.2rem;
  ul{
    li{
      position: relative;
      a{
        color: $bg-12;
        font-size: 13px;
        display: block;
        line-height: 44px;
        padding: 0 10px;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: 10px;
          bottom: 0;
          background: $bg-1;
          transform: scale(0, 1);
          transform-origin: right;
          transition: transform .4s ease;
          height: 2px;
          width: calc(100% - 20px);
        }
        &.active , &:hover{
          &:after{
            transform: scale(1, 1);
            transform-origin: left;
          }
        }
      }
      ul{
        position: absolute;
        top: calc(100% + 20px);
        left:-15px;
        background: $bg-white;
        z-index: 1;
        width: 200px;
        padding: 1rem;
        box-shadow: rgba(0,0,0,.5) 0 6px 10px;
        border-radius: 0 0 10px 10px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out .4s;
        z-index: 2;
      }
      &:hover{
        ul{
          top: 100%;
          left: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.mainHeader {
    position: absolute;
    top: 0;
    left: 0;
}

.innerHeader {
    position: static;
    top: -100px;
}

header.sticky,
.innerHeader.sticky {
    top: 0;
    position: fixed;
    z-index: 9;
    background: #fff;
}

.signUpLang{
  ul{
      li{
        position: relative;
          &:not(:last-child){
              margin-right: 0.7rem;
          }
          .hSrcBtn {
            font-size: 24px;
            color: #333;
            cursor: pointer;
          }
          a{
              font-size: 14px;
              font-weight: 600;
              display: block;
              border-radius: 30px;
              &.logInBtn{
                  background: $bg-1;
                  border: none;
                  color: #fff;
                  line-height: 34px;
                  padding: 0 15px;
                  .svg-inline--fa{
                    margin-right: 5px;
                    font-size: 14px;
                  }
                  &:hover{
                    background: $bg-3;
                  }
              }
              &.signUpBtn{
                  background: transparent;
                  color: #333;
                  border: $bg-1 1px solid;
                  line-height: 34px;
                  padding: 0 15px;
                  .svg-inline--fa{
                    margin-right: 5px;
                    font-size: 14px;
                  }
                  &:hover{
                    background: $bg-1;
                    color: $bg-6;
                  }
              }
          }
          
        &.cartIcon{
          position: relative;
          a{
            span{
              position: absolute;
              background: $bg-1;
              color: $bg-white;
              font-size: 10px;
              height: 22px;
              width: 22px;
              line-height: 22px;
              text-align: center;
              border-radius: 50%;
              left: 0;
              bottom: -3px;
            }
          }
        }
        &:hover{
          .accoutDd {
            opacity: 1;
            visibility: visible;
          }
        }
        
      }
  }
  .accoutDd {
    transition: all ease-in-out .4s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    background: #fff;
    right: 0;
    z-index: 2;
    width: 300px;
    box-shadow: rgba(0,0,0,.7) 0 8px 10px -5px;
    .adtLeft{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
      flex: 0 0 60px;
      max-width: 60px;
      span{
        display: block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        background: #333;
        color: #fff;
        font-weight: bold;
        font-size: 26px;
        text-align: center;
      }
    }
    .adtRight{
      flex:1 0 0%;
      padding-left: 10px;
      h4{
        font-size: 17px;
        padding-bottom: 5px;
      }
      h6{
        font-weight: normal;
        font-size: 11px;
        color: #808080;
        padding: 0;
      }
    }
    .accoutDdList {
      padding: 10px 0;
      border-top: 1px solid #e3e3e3;
      ul{
        li{
          padding: 0;
          margin: 0 0 3px;
          a{
            color: #555;
            line-height: 2.2;
            padding: 0 15px;
            font-weight: normal;
            font-size: 13px;
          }
        }
      }
    }
    .accoutDdTop {
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}
.humbergar {
  position: fixed;
	z-index: 5;
	top: 17px;
	right: 20px;
  span {
    width: 30px;
    height: 2px;
    background: #cbc394;
    display: block;
    margin: 5px 0;
    transition: all ease-in-out .3s;
    &:last-child {
      width: 20px;
      margin: 0 auto;
      transition: all ease-in-out .3s;
    }
    &:last-child {
      width: 20px;
      margin: 0 auto;
      transition: all ease-in-out .3s;
    }
  }
}
.midHeader{
  .topLinks {
    margin-bottom: .5rem;
    padding: 0;
    ul {
      padding-bottom: 0 !important;
      li {
        a {
          text-transform: capitalize;
          padding: 0;
        }
      }
    }
  }
}


