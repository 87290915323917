@font-face {
    font-family: 'Back to Black Demo';
    src: url('BacktoBlackDemo.woff2') format('woff2'),
        url('BacktoBlackDemo.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeue-Regular.woff2') format('woff2'),
        url('BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-Bold.woff2') format('woff2'),
        url('OfeliaStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-Light.woff2') format('woff2'),
        url('OfeliaStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-BoldItalic.woff2') format('woff2'),
        url('OfeliaStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-LightItalic.woff2') format('woff2'),
        url('OfeliaStd-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std Book';
    src: url('OfeliaStd-Book.woff2') format('woff2'),
        url('OfeliaStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std Book';
    src: url('OfeliaStd-BookItalic.woff2') format('woff2'),
        url('OfeliaStd-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-MediumItalic.woff2') format('woff2'),
        url('OfeliaStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-Medium.woff2') format('woff2'),
        url('OfeliaStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-Ultralight.woff2') format('woff2'),
        url('OfeliaStd-Ultralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ofelia Std';
    src: url('OfeliaStd-UltralightItalic.woff2') format('woff2'),
        url('OfeliaStd-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src: url('TimesNewRomanPSMT.woff2') format('woff2'),
        url('TimesNewRomanPSMT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src: url('TimesNewRomanPS-BoldMT.woff2') format('woff2'),
        url('TimesNewRomanPS-BoldMT.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src: url('TimesNewRomanPS-BoldItalicMT.woff2') format('woff2'),
        url('TimesNewRomanPS-BoldItalicMT.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src: url('TimesNewRomanPS-ItalicMT.woff2') format('woff2'),
        url('TimesNewRomanPS-ItalicMT.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

