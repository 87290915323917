$bg-1:#000;
$bg-2:#b7e488;
$bg-3:#04041c;
$bg-4:#676565;
$bg-5:#aeafad;
$bg-6:#fff;
$bg-7:#befbd4;
$bg-8:#ebfbad;
$bg-9:#c6f09a;
$bg-10:#599e14;
$bg-11:#457e0d;
$bg-12:#000404;
$bg-13:#939598;
$bg-14:#cbc394;
$bg-white:#fff;
$bg-red:#a11a1a;
$bg-15:#ededed;